export const Preconfigurados = [
    {
        id: 1,
        Descripcion: "PC NSX ASUS EDITION I3 16GG 500GB 1650 F",
        Imagen: "PGI3VNS5F-01_1.jpg",
        Moneda: "u$s",
        PreConfigurado: "PGI3VNS5F-01",
        Precio: 652.66,
        detalle: [
            {Alias: "90MB18A0-M0E",
                Descripcion: "MOTHER ASUS PRIME B460M-A R2.0 11VA/10MA",
                DescripcionLarga: "",
                Imagen: "90MB18A0-M0E_1.jpg",
                Marca: "159",
                Moneda: "u$s",
                Precio: 80.07,
                quantity: "1",
                type: "motherboard"},
            {
                Alias: "PVR48G320C6",
                Descripcion: "MEMORIA RAM PATRIOT 8GB DDR4-3200 V RGB",
                DescripcionLarga: "",
                Imagen: "PVR48G320C6_1.jpg",
                Marca: "PAA",
                Moneda: "u$s",
                Precio: 41.18,
                quantity: "2",
                type: "ram",
            },
            {
                Alias: "90YV0CV0-MVA",
                Descripcion: "VGA NVIDIA ASUS GTX 1650 PHOENIX OC 4GB",
                DescripcionLarga: "",
                Imagen: 0,
                Marca: "159",
                Moneda: "u$s",
                Precio: 250.03,
                quantity: "1",
                type: "video",
            },
            {
                Alias: "BX807011010F",
                Descripcion: "PROCESADOR INTEL CORE I3 10100F LGA1200",
                DescripcionLarga: "",
                Imagen: "BX807011010F_1.jpg",
                Marca: "044",
                Moneda: "u$s",
                Precio: 77.49,
                quantity: "1",
                type: "microprocesador",
            }
        ]
    },
    {
        id: 1,
        Descripcion: "PC NSX GAMING MAX R5 3600 16GB RX 6600XT",
        Imagen: "PGR5VAS5F-09_1.jpg",
        Moneda: "u$s",
        PreConfigurado: "PGR5VAS5F-09",
        Precio: 1080.03,
        detalle: [
            {
                Alias: "90MXB9N0A0UA",
                Descripcion: "MOTHER ASROCK B450M-HDV R4.0 AM4",
                DescripcionLarga: "",
                Imagen: "90MXB9N0A0UA_1.jpg",
                Marca: "ASK",
                Moneda: "u$s",
                Precio: 58.59,
                quantity: "1",
                type: "motherboard"
            },
            {
                Alias: "PVR48G320C6",
                Descripcion: "MEMORIA RAM PATRIOT 8GB DDR4-3200 V RGB",
                DescripcionLarga: "",
                Imagen: "PVR48G320C6_1.jpg",
                Marca: "PAA",
                Moneda: "u$s",
                Precio: 41.18,
                quantity: "2",
                type: "ram",
            },
            {
                Alias: "1A1G0035430B",
                Descripcion: "VGA AMD POWERCOLOR RX 6600 XT FIGHT BULK",
                DescripcionLarga: "",
                Imagen: 0,
                Marca: "POW",
                Moneda: "u$s",
                Precio: 490,
                quantity: "1",
                type: "video",
            },
            {
                Alias: "BX8070110400",
                Descripcion: "PROCESADOR INTEL CORE I5 10400 LGA1200",
                DescripcionLarga: "",
                Imagen: "BX8070110400_1.jpg",
                Marca: "044",
                Moneda: "u$s",
                Precio: 169.49,
                quantity: "1",
                type: "microprocesador",
            },
            {
                Alias: "QBG405TF",
                Descripcion: "GABINETE QBOX GAMING 405T 650W 80+BRONCE",
                DescripcionLarga: "",
                Imagen: "QBG405TF_1.jpg",
                Marca: "QBO",
                Moneda: "u$s",
                Precio: 78.27,
                quantity: "1",
                type: "gabinete",
            }
        ]
    }
]