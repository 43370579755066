import React, { useContext } from 'react'
import './Price.css'
import { PreconfiguradoContext } from './ContextConfigurador/PreconfiguradoContext';
import { MarcaProcesadorContext } from './ContextConfigurador/MarcaProcesadorContext';
import { ListadoContext } from './ContextConfigurador/ListadoContext';


export default function Price() {
	const { price } = useContext(PreconfiguradoContext);
	const { paso } = useContext(MarcaProcesadorContext);
	const { typeDetalle, listado } = useContext(ListadoContext);

	let aComponentesAgregados = [];
	typeDetalle.forEach(element => {
		if (listado[element].length !== 0) {
			aComponentesAgregados.push(listado[element]);
		}
	});
	let itemsAgregados = []
	aComponentesAgregados.forEach(element => {
		element.forEach(item => {
			itemsAgregados.push(item);
		})
	});

	return (
		itemsAgregados.length > 6 ? <>
			<span className="my-3" id="totalGastado">
				PRECIO TOTAL  {' ARS $  ' + new Intl.NumberFormat().format(price)}
			</span><br />
		</> :
			<>
				<span className="my-3" id="totalGastado">
					PRECIO TOTAL  {' ARS $  ' + new Intl.NumberFormat().format(price)}
				</span><br />
			</>
	)
}
