import React from 'react'
import { Button } from 'react-bootstrap'

const BtnInicio = () => {
    return (
        <Button variant=" btn-block" id="backToHome" type='submit' href='/'>
            VOLVER AL INICIO
        </Button>
    )
}

export default BtnInicio