import React, { createContext, useEffect, useState } from 'react';
import firebaseConfig from '../firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

export const MarcaProcesadorContext = createContext();

export const MarcaProcesadorProvider = ({ children }) => {
    const [busqueda, setBusqueda] = useState('');
    const searchInput = busqueda.toLowerCase().replace(/ /g, '');
    const [loading, setLoading] = useState(false);
    const [datos, setDatos] = useState([]);
    const [marca, setMarca] = useState('');
    const [componentes, setComponentes] = useState('');
    const [show, setShow] = useState(false);
    const [datito, setDatito] = useState(null);
    const [paso, setPaso] = useState('microprocesador');
    const handleShow = () => setShow(true)
   

    const handleClickMarca = (e) => {
        handleShow();
        setMarca(e.target.value);
    };

    const initialDataMarca = () => {
        setMarca('');
        setComponentes('');
    };

    const infoDidMount = (componente) => {
        setPaso(componente);
    };



    useEffect(() => {
        firebase.initializeApp(firebaseConfig);
        const database = firebase.database();
        const dataRef = database.ref();

        const onDataChange = (snapshot) => {
            const newData = snapshot.val();
            const dataArray = newData ? Object.values(newData) : [];
            setDatito(dataArray);
        };

        console.log(onDataChange)

        dataRef.on('value', onDataChange);

        return () => {
            dataRef.off('value', onDataChange);
        };
    }, []);

    useEffect(() => {
        setLoading(true)
        if (datito && datito.length > 0) {
            const pasoData = datito.find((item) => item[paso] !== undefined);
            const dataString = pasoData ? Object.values(pasoData[paso]) : [];
            if (dataString && dataString.length > 0) {
                const componentesConStock = dataString.filter(componente => componente.Stock > 0);
                setComponentes(componentesConStock);
            } else {
                setComponentes([]);
            }
        }
        setTimeout(() => {
                      setLoading(false);
             }, 1000);
    }, [datito, paso]);


    // Manejo el stock con props para poder implementarlo de forma dinamica

    const restarStockSiPosible = (pasoActual, aliasProducto) => {
        const productoRef = firebase.database().ref(`10obQcb9T3sDd7EBOmTvhselrlqtZ5vwfHh8ZJFPpxhY/${pasoActual}/${aliasProducto}/Stock`);

        productoRef.once('value')
            .then((snapshot) => {
                const stock = snapshot.val();
                if (stock && stock > 0) {
                    const nuevoStock = stock - 1;
                    productoRef.set(nuevoStock)
                        .then(() => {
                            console.log('Stock actualizado exitosamente:', nuevoStock);
                        })
                        .catch((error) => {
                            console.error('Error al actualizar el stock:', error);
                        });
                } else {
                    console.log('El stock es 0 o no está definido.');
                }
            })
            .catch((error) => {
                console.error('Error al obtener el stock:', error);
            });
    };

    const restarStockDeProductos = (productos) => {
        productos.forEach((producto) => {
            restarStockSiPosible(producto.Tipo, producto.Alias);
        });
    };


    const data = {
        marca,
        setMarca,
        handleClickMarca,
        initialDataMarca,
        infoDidMount,
        componentes,
        setShow,
        paso,
        setPaso,
        busqueda,
        setBusqueda,
        searchInput,
        loading,
        setLoading,
        datos,
        setDatos,
        restarStockSiPosible,
        restarStockDeProductos,
    };

    return (
        <MarcaProcesadorContext.Provider value={data}>
            {children}
        </MarcaProcesadorContext.Provider>
    );
};


// import React, { createContext, useEffect, useState } from 'react'
// import { db } from '../firebase';
// import { collection, query, getDocs } from 'firebase/firestore';
// import axios from 'axios';

// export const MarcaProcesadorContext = createContext();

// export const MarcaProcesadorProvider = ({ children }) => {
//     // Búsqueda
//     const [busqueda, setBusqueda] = useState('');
//     const searchInput = busqueda.toLowerCase().replace(/ /g, '') // Declaro y sanitizo la variable de búsqueda.
//     const [loading, setLoading] = useState(false);
//     const [datos, setDatos] = useState([]);
//     const armadoUrl = "https://docs.google.com/spreadsheets/d/e/2PACX-1vR-HxyKDiIuoyLGcN_4afH9-uvDE6t9Vx28PhlULGWshyAH2j58sVRbIOFosZzGFnUkxE8QB8H4ZJdj/pub?gid=698782113&single=true&output=csv"
//     const auricularUrl = "https://docs.google.com/spreadsheets/d/e/2PACX-1vR-HxyKDiIuoyLGcN_4afH9-uvDE6t9Vx28PhlULGWshyAH2j58sVRbIOFosZzGFnUkxE8QB8H4ZJdj/pub?gid=608464710&single=true&output=csv"
//     const coolerUrl = "https://docs.google.com/spreadsheets/d/e/2PACX-1vR-HxyKDiIuoyLGcN_4afH9-uvDE6t9Vx28PhlULGWshyAH2j58sVRbIOFosZzGFnUkxE8QB8H4ZJdj/pub?gid=386668311&single=true&output=csv"
//     const discoUrl = "https://docs.google.com/spreadsheets/d/e/2PACX-1vR-HxyKDiIuoyLGcN_4afH9-uvDE6t9Vx28PhlULGWshyAH2j58sVRbIOFosZzGFnUkxE8QB8H4ZJdj/pub?gid=1891561676&single=true&output=csv"
//     const fuenteUrl = "https://docs.google.com/spreadsheets/d/e/2PACX-1vR-HxyKDiIuoyLGcN_4afH9-uvDE6t9Vx28PhlULGWshyAH2j58sVRbIOFosZzGFnUkxE8QB8H4ZJdj/pub?gid=1889256691&single=true&output=csv"
//     const gabineteUrl = "https://docs.google.com/spreadsheets/d/e/2PACX-1vR-HxyKDiIuoyLGcN_4afH9-uvDE6t9Vx28PhlULGWshyAH2j58sVRbIOFosZzGFnUkxE8QB8H4ZJdj/pub?gid=616541624&single=true&output=csv"
//     const licenciaUrl = "https://docs.google.com/spreadsheets/d/e/2PACX-1vR-HxyKDiIuoyLGcN_4afH9-uvDE6t9Vx28PhlULGWshyAH2j58sVRbIOFosZzGFnUkxE8QB8H4ZJdj/pub?gid=1442850132&single=true&output=csv"
//     const microprocesadorUrl = "https://docs.google.com/spreadsheets/d/e/2PACX-1vR-HxyKDiIuoyLGcN_4afH9-uvDE6t9Vx28PhlULGWshyAH2j58sVRbIOFosZzGFnUkxE8QB8H4ZJdj/pub?gid=1473748413&single=true&output=csv"
//     const monitorUrl = "https://docs.google.com/spreadsheets/d/e/2PACX-1vR-HxyKDiIuoyLGcN_4afH9-uvDE6t9Vx28PhlULGWshyAH2j58sVRbIOFosZzGFnUkxE8QB8H4ZJdj/pub?gid=1434465689&single=true&output=csv"
//     const motherboardUrl = "https://docs.google.com/spreadsheets/d/e/2PACX-1vR-HxyKDiIuoyLGcN_4afH9-uvDE6t9Vx28PhlULGWshyAH2j58sVRbIOFosZzGFnUkxE8QB8H4ZJdj/pub?gid=1547183619&single=true&output=csv"
//     const mouseUrl = "https://docs.google.com/spreadsheets/d/e/2PACX-1vR-HxyKDiIuoyLGcN_4afH9-uvDE6t9Vx28PhlULGWshyAH2j58sVRbIOFosZzGFnUkxE8QB8H4ZJdj/pub?gid=66402924&single=true&output=csv"
//     const ramUrl = "https://docs.google.com/spreadsheets/d/e/2PACX-1vR-HxyKDiIuoyLGcN_4afH9-uvDE6t9Vx28PhlULGWshyAH2j58sVRbIOFosZzGFnUkxE8QB8H4ZJdj/pub?gid=2109911683&single=true&output=csv"
//     const sillaUrl = "https://docs.google.com/spreadsheets/d/e/2PACX-1vR-HxyKDiIuoyLGcN_4afH9-uvDE6t9Vx28PhlULGWshyAH2j58sVRbIOFosZzGFnUkxE8QB8H4ZJdj/pub?gid=775078258&single=true&output=csv"
//     const tecladoUrl = "https://docs.google.com/spreadsheets/d/e/2PACX-1vR-HxyKDiIuoyLGcN_4afH9-uvDE6t9Vx28PhlULGWshyAH2j58sVRbIOFosZzGFnUkxE8QB8H4ZJdj/pub?gid=930413742&single=true&output=csv"
//     const videoUrl = "https://docs.google.com/spreadsheets/d/e/2PACX-1vR-HxyKDiIuoyLGcN_4afH9-uvDE6t9Vx28PhlULGWshyAH2j58sVRbIOFosZzGFnUkxE8QB8H4ZJdj/pub?gid=1383479685&single=true&output=csv"

//     /* *********** EMPIEZA LOGICA DE MODAL Y ELECCION DE MARCA */
//     const [marca, setMarca] = useState('')
//     const [componentes, setComponentes] = useState('')
//     // eslint-disable-next-line no-unused-vars
//     const [show, setShow] = useState(false);
//     const handleShow = () => setShow(true);
//     const handleClickMarca = (e) => {
//         handleShow();
//         setMarca(e.target.value);
//     }
//     const initialDataMarca = () => {
//         setMarca('');
//         setComponentes('');
//     }
//     /* *********** TERMINA LOGICA DE MODAL Y ELECCION DE MARCA */

//     /* ********** OBTENGO LOS DATOS DE LOS COMPONENTES */
//     const [paso, setPaso] = useState('microprocesador')
//     const infoDidMount = (/* endpoint,  */componente) => {
//         setPaso('')
//         // handleClickTraigoComponentes(endpoint);
//         handleClickTraigoComponentes(componente);

//         setPaso(componente)
//     }

//     console.log('este es el paso', paso)



//     const handleClickTraigoComponentes = async (componente) => {
//         //  const q = query(collection(db, componente));
//         //   const docs = [];
//         setLoading(true)
//         let url = '';

//         switch (componente) {
//             case 'armado':
//                 url = armadoUrl;
//                 break;
//             case 'auricular':
//                 url = auricularUrl;
//                 break;
//             case 'cooler':
//                 url = coolerUrl;
//                 break;
//             case 'disco':
//                 url = discoUrl;
//                 break;
//             case 'fuente':
//                 url = fuenteUrl;
//                 break;
//             case 'gabinete':
//                 url = gabineteUrl;
//                 break;
//             case 'licencia':
//                 url = licenciaUrl;
//                 break;
//             case 'microprocesador':
//                 url = microprocesadorUrl;
//                 break;
//             case 'monitor':
//                 url = monitorUrl;
//                 break;
//             case 'motherboard':
//                 url = motherboardUrl;
//                 break;
//             case 'mouse':
//                 url = mouseUrl;
//                 break;
//             case 'ram':
//                 url = ramUrl;
//                 break;
//             case 'silla':
//                 url = sillaUrl;
//                 break;
//             case 'teclado':
//                 url = tecladoUrl;
//                 break;
//             case 'video':
//                 url = videoUrl;
//                 break;

//             default:
//                 url = microprocesadorUrl;
//         }

//         const response = await axios.get(url);
//         const parsedData = parseCSV(response.data);


//         setTimeout(() => {
//             setLoading(false);
//         }, 1000);

//         setComponentes(parsedData);
        

//         console.log(componentes);
//         return componentes;
//     };



//     const parseCSV = (csvText) => {
//         const rows = csvText.split(/\r?\n/);
//         const headers = rows[0].split(',');
//         const data = [];

//         for (let i = 1; i < rows.length; i++) {
//             const rowData = rows[i].split(',');
//             const rowObject = {};

//             for (let j = 0; j < headers.length; j++) {
//                 // Si la columna es 'Precio', convierte el valor a número
//                 if (headers[j] === 'Precio') {
//                     rowObject[headers[j]] = parseFloat(rowData[j]);
//                 } else {
//                     rowObject[headers[j]] = rowData[j];
//                 }
//             }

//             data.push(rowObject);
//         }

//         return data;
//     };

//     /* *********************************************** */

//     const data = {
//         marca,
//         setMarca,
//         handleClickMarca,
//         initialDataMarca,
//         infoDidMount,
//         componentes,
//         setComponentes,
//         setShow,
//         paso,
//         setPaso,
//         busqueda,
//         setBusqueda,
//         searchInput,
//         loading,
//         setLoading,
//         datos,
//         setDatos
//     }
//     return (
//         <MarcaProcesadorContext.Provider value={data}>
//             {children}
//         </MarcaProcesadorContext.Provider>
//     )
// }