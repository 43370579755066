import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const CompraExitosa = () => {
    const location = useLocation();
    const [paymentInfo, setPaymentInfo] = useState(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const paymentId = searchParams.get('payment_id');
        const status = searchParams.get('status');


        if (paymentId && status) {
            setPaymentInfo({ paymentId, status });
        }
    }, [location.search]);

    console.log(paymentInfo)

    return (
        <div>
            <div style={{ marginTop: '2em' }}>
                <h1 style={{ textAlign: 'center' }}>GRACIAS POR SU COMPRA!!!!</h1>
                <h2 style={{ textAlign: 'center' }}>Su pago se ha procesado correctamente</h2>
                <br />
                {paymentInfo !== null && (
                    <h3 style={{ textAlign: 'center', textDecoration: 'underline' }} >ID de Pago: {paymentInfo.paymentId} </h3>
                )}

            </div>
        </div>
    );
};

export default CompraExitosa;
