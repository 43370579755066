import React, { useContext } from 'react'
import { PreconfiguradoContext } from './ContextConfigurador/PreconfiguradoContext';
import { ListadoContext } from './ContextConfigurador/ListadoContext';
import './MapeoOrder.css'
import Price from './Price';

export const MapeoOrder = ({ listadoResumen }) => { //LISTADORESUMEN VIENE DE ORDER.JS
    const { restaPrecios, price } = useContext(PreconfiguradoContext);
    const { listado, listadoResumenCompra, componentesOpcionales } = useContext(ListadoContext);

    const handleClick = (item) => {
        listadoResumen.forEach((element, index) => {
            if (element.Alias === item.Alias) {
                restaPrecios(item)
                listadoResumen.splice(index, 1)
                listado[item.Tipo].splice(0, 1)
                if (listadoResumenCompra[item.Tipo]) {
                    listadoResumenCompra[item.Tipo].splice(0, 1)
                }
            }
        })
    }

    return (
        <div className='contenedorFormulario text-center'>
            <div className='mb-4'>
                {listadoResumen.map((item) => (
                    <div className={`row  p-2`} style={{ borderRadius: "5px" }} key={item.Alias} id={`order__${item.Alias}`}>
                        {/* <div className="col-sm-3 col-4 col-lg-3 col-md-2">
                            <img src={`${item.Imagen}`} className="imagenProductoCompra__order img-fluid" style={{ margin: "auto" }} alt="" border="1" />
                        </div> */}
                        <div className="col-8" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <p className='text-start'>
                                <span id={`resumenCantidad_${item.Alias}`}>{item.Cantidad ? item.Cantidad : "1"} x </span>
                                {item.Descripcion}
                            </p>

                        </div>
                        <div className='col-3' style={{ color: "rgb(40 177 40)", fontWeight: 700 }}>
                            ARS $  {item.Precio}
                        </div>
                        {componentesOpcionales[item.Tipo] && componentesOpcionales[item.Tipo].find(art => art.Alias === item.Alias) && item.Tipo !== 'armado' &&
                            <div className='col-1' style={{ display: "flex", }}>
                                <i className="fa-solid fa-trash-can descliquearComponente" style={{ cursor: "pointer", display: 'flex', alignItems: "center", justifyContent: "center", flexDirection: 'column' }} aria-hidden="true"
                                    sku={`${item.Precio && item.Precio}`} onClick={() => handleClick(item)}>
                                </i>
                            </div>
                        }
                    </div>
                ))}
            </div>
            <hr />
            <div className='roew m-3 d-flex justify-content-start align-center'>
                {/* <Price /> */}
                <div className='col-8 col-sm-7 text-start'>
                    <h5>PRECIO TOTAL</h5>
                </div>
                <div className='col-4 col-sm-5'>
                    <h5 style={{color: "rgb(40 177 40)", fontWeight: 700}}>{`ARS $  ` + new Intl.NumberFormat().format(price)}</h5>
                </div>
            </div>
        </div>
    )
}
