/* eslint-disable jsx-a11y/anchor-is-valid */
import './ConfiguradorColumnasStyle.css';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import React, { useContext, useEffect, useState } from 'react'

import { GrPowerReset } from "react-icons/gr";
import { IoMdArrowRoundBack } from "react-icons/io";
import { ListadoContext } from '../ContextConfigurador/ListadoContext';
import { MarcaProcesadorContext } from '../ContextConfigurador/MarcaProcesadorContext';
import { MdDeleteForever } from "react-icons/md";
import { PreconfiguradoContext } from '../ContextConfigurador/PreconfiguradoContext';
import Spinner from '../../../Spinner/Spinner';
import Swal from 'sweetalert2';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { nanoid } from 'nanoid';

const MapeoComponentes = () => {
	const { componentes, paso, infoDidMount, busqueda, setBusqueda, searchInput, loading } = useContext(MarcaProcesadorContext);
	const { addComponentList, listado, removeComponentList, typeDetalle } = useContext(ListadoContext);
	const { sumaPrecios, restaPrecios, counterRam, setCounterRam, counterDisco, setCounterDisco } = useContext(PreconfiguradoContext);
	const navigate = useNavigate();
	const { param } = useParams();

	// Tooltips para probar botones de back y reset
	const renderTooltip = props => (
		<Tooltip {...props}>Quitar producto</Tooltip>
	);
	const toolTipBack = props => (
		<Tooltip {...props}>Anterior componente</Tooltip>
	);
	const tooltipReset = props => (
		<Tooltip {...props}>Reiniciar configuracion</Tooltip>
	);

	// Resetear configuracion vuelve a armar de cero

	const reset = () => {
		window.location.replace('');
	}

	let aComponentesAgregados = [];
	typeDetalle.forEach(element => {
		if (listado[element].length !== 0) {
			aComponentesAgregados.push(listado[element]);
		}
	});
	let listadoAgregados = []
	aComponentesAgregados.forEach(element => {
		element.forEach(item => {
			listadoAgregados.push(item);
		})
	});

	// Declaro una variable para verificar si cooler esta incluido en la configuracion

	const coolerIncluido = listadoAgregados.length > 0 &&
		listadoAgregados[0].CoolerStock &&
		listadoAgregados[0].CoolerStock.toLowerCase().includes("si");

	console.log(coolerIncluido, "cooler incluye")

	console.log(listadoAgregados, "este es el elistadoagregados")

	// ---------------------------------------Lógica de filtrado de productos----------------------------------------------------------

	const filteredProducts = componentes.filter(product =>
		product.Descripcion.toLowerCase().includes(searchInput)
	)

	const handleOnChange = (e) => {
		setBusqueda(e.target.value);
	}

	// Refactor de handler para retornar a paso anterior 

	const handleBackPaso = (index) => {
		if (paso === "cooler") {
			infoDidMount(typeDetalle[index - 1]);
		} else {
			infoDidMount(typeDetalle[index]);
		}
	}


	function getBackPasoValue(paso) {
		const pasos = ['motherboard', 'ram', 'disco', 'gabinete', 'video', 'fuente', 'armado', 'cooler', 'licencia', 'monitor', 'teclado', 'mouse', 'auricular', 'silla'];
		return pasos.indexOf(paso);
	}

	// Alerta de aviso para finalizar compra una vez que llegamos a determinado paso

	const alertaFinalizacionCompra = () => {
		const idPedido = nanoid(10)
		sessionStorage.setItem("idPedido", idPedido)
		Swal.fire({
			html: '<h3><b>Ya seleccionaste todos los items necesarios para armar tu pc.</b></h3> <br> <h5>Ahora puedes revisar tu pedido o bien finalizar tu pedido.</h5>',
			showCancelButton: true,
			confirmButtonText: 'Seguir comprando.',
			cancelButtonText: 'Finalizar compra.',
			reverseButtons: true
		}).then((result) => {
			if (result.isConfirmed) {
			} else {
				navigate(`/Order/${param}`);
			}
		});
	}

	// Listado pedido completo
	const pedidoCompleto =
		listado.microprocesador.length > 0 &&
		listado.motherboard.length > 0 &&
		listado.ram.length > 0 &&
		listado.disco.length > 0 &&
		listado.gabinete.length > 0 &&
		listado.video.length > 0 &&
		listado.fuente.length > 0 &&
		listado.cooler.length > 0;

	const [prevPedidoCompleto, setPrevPedidoCompleto] = useState(false);

	// Manejo la muestra de la alerta de la finalizacion de compra cuando los items seleccionados son los requeridos

	useEffect(() => {
		if (pedidoCompleto && !prevPedidoCompleto) {
			alertaFinalizacionCompra();
		}
		setPrevPedidoCompleto(pedidoCompleto);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pedidoCompleto, prevPedidoCompleto]);

	console.log("pedido completo es: " + pedidoCompleto)
	//

	//---------------------------------------------------------------------------------------------------------------------------------
	const handleClick = (paso, item) => {
		sumaPrecios(item.Precio, listado[paso], item.Alias, counterRam)//PASO ALIAS COMO PARAMETRO
		addComponentList(paso, item)
		console.log(item)

		//PASO AL SIGUIENTE COMPONENTE
		let indexComponenteElegido = typeDetalle.indexOf(item.Tipo)
		let proximoComponente = paso === "fuente" ? indexComponenteElegido + 2 : indexComponenteElegido + 1; // Salteo paso armado
		console.log(componentes)
		console.log(indexComponenteElegido)
		console.log(proximoComponente)
		console.log(typeDetalle[proximoComponente])

		if (paso !== "ram" && paso !== "cooler") {
			toast.success(item.Descripcion + " agregado a tu configuración ", {
				position: 'top-right'
			})
		}
		if (paso === "ram") {
			toast.success(item.Descripcion + " agregado, puedes seleccionar cantidad de Slots en este paso. ", {
				position: 'top-right',
				duration: 4000
			});
		}

		if (paso === "fuente" && listadoAgregados.length > 0 && coolerIncluido) {
			toast.success(
				<span style={{ fontSize: '20px', textAlign: 'center' }}>
					<b>El microprocesador que elegiste en el primer paso incluye Cooler. Si deseas, puedes agregarle otro.</b>
				</span>,
				{
					position: 'top-right',
					duration: 7000
				}
			);
		}

		setBusqueda('')

		//pedidoCompleto && alertaFinalizacionCompra();

		if (proximoComponente <= 14 && paso !== 'ram'/* && paso !== 'disco' */) { //DESCOMENTO ESTO --->( && paso !== 'disco') PARA USAR CONTADOR
			infoDidMount(typeDetalle[proximoComponente])
		} else if (proximoComponente <= 13 && paso === 'ram') {
			setCounterRam(1)

		} else if (proximoComponente <= 13 && paso === 'disco') {
			setCounterDisco(1)
		}
	}



	const handleSumarCantidadPrecio = (componente) => {
		let esDisco = componente.Tipo === 'disco';
		if (listadoAgregados[1]) {
			if (!esDisco && counterRam < listadoAgregados[1].Slots) {
				let precio = listado[paso][0].Precio;
				setCounterRam(counterRam + 1);
				sumaPrecios(precio, listado[paso], componente, counterRam);
			} else if (esDisco && counterDisco < listadoAgregados[1].Slots) {
				let precio = listado[paso][0].Precio;
				setCounterDisco(counterDisco + 1);
				sumaPrecios(precio, listado[paso], componente, counterDisco);
			}
		} else {
			console.log("No se pudo encontrar la cantidad de slots disponibles.");
		}
	};



	const handleRestarCantidadPrecio = (item) => { //RESTAR CANTIDAD EN RAMS Y DISCOS
		let esDisco = item.Tipo === 'disco';

		if (!esDisco && counterRam > 1) {
			setCounterRam(counterRam - 1)
			restaPrecios(item, listado[paso], counterRam)

		} else if (esDisco && counterDisco > 1) {
			setCounterDisco(counterDisco - 1)
			restaPrecios(item, listado[paso], counterDisco)
		}
	}

	// Esta funcion es la que se usa para pasar al siguiente componente
	const cambiarPaso = (item) => {
		let indexComponenteElegido = typeDetalle.indexOf(item.Tipo)
		let proximoComponente = indexComponenteElegido + 1;
		console.log(indexComponenteElegido)
		console.log(proximoComponente)
		proximoComponente <= 8 && infoDidMount(typeDetalle[proximoComponente])
		console.log(proximoComponente)
		setBusqueda('')
	}

	const handleRemoveItem = (item) => {
		if (item.Tipo === 'ram') {
			restaPrecios(item, listado[paso][0], counterRam)
		} else if (item.Tipo === 'disco') {
			restaPrecios(item, listado[paso][0], counterDisco)
		} else {
			restaPrecios(item)
		}

		removeComponentList(item)
		toast.error(item.Descripcion + " quitado de tu configuracion.", {
			position: 'top-right'
		})
	}
	let widthMin = window.innerWidth < 768
	return (
		<>

			<div class="group-input-filtered">
				<svg class="icon-filtered" aria-hidden="true" viewBox="0 0 24 24"><g><path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path></g></svg>
				<input value={busqueda} onChange={handleOnChange} placeholder="Buscá por palabra clave..." type="search" class="input-filtered" />
			</div>

			<div className='botones__sgte__ant d-flex  m-3' style={{ cursor: 'pointer' }} id='resetButtons'>
				{paso !== "microprocesador" && (
					<>
						<a onClick={() => handleBackPaso(getBackPasoValue(paso))}>
							<OverlayTrigger placement="left" overlay={toolTipBack}>
								{/* <i> */}
								<p>ANTERIOR</p>
								{/* <IoMdArrowRoundBack size='1.5em' cursor='pointer' color='#1e6dff' /> */}
								{/* </i> */}
							</OverlayTrigger>
						</a>
						<a onClick={() => reset()} style={{ marginLeft: "30px" }}>
							<OverlayTrigger placement="right" overlay={tooltipReset}>
								{/* <i> */}
								<p>REINICIAR</p>
								{/* <GrPowerReset size='1.5em' cursor='pointer' color='#1e6dff' /> */}
								{/* </i> */}
							</OverlayTrigger>
						</a>
					</>
				)}
			</div>
			<h5 className='confEleg'>NSX {'>'} {paso.toUpperCase()}</h5>

			{paso === 'disco' && listado.disco.length > 0 && (
				<div className='botones__sgte__ant fade-in d-flex justify-content-end m-3'>
					<button onClick={() => cambiarPaso(listado[paso][0])}>
						SIGUIENTE
						<div className="arrow-wrapper">
							<div className="arrow__right"></div>
						</div>
					</button>
				</div>
			)}

			{loading ? <Spinner /> : <div className={`${!widthMin && 'row'} contenedorComponentes`}>
				{paso === "motherboard" && listadoAgregados.length > 0 ?
					filteredProducts.length > 0 ? filteredProducts.map((item) => (
						<>
							{listadoAgregados[0].Tags === item.Tags &&
								<div className="col-xl-3  col-8">
									<div className="cardComponents my-4" key={item.id}>
										<div className="cardComponents-details">
											{listado[paso].find(art => art.Alias === item.Alias) &&
												<OverlayTrigger placement="top" overlay={renderTooltip}>
													<motion.i whileHover={{ scale: 1.1 }}
														whileTap={{ scale: 0.9 }} className="descliquearComponente text-align-end"
													>
														<MdDeleteForever aria-hidden="true"
															cursor='pointer'
															color='#EE4245'
															size='2.5em'
															onClick={() => handleRemoveItem(item)} />
													</motion.i>
												</OverlayTrigger>
											}
											<img src={item.Imagen} className="btn-verMas img-componente" alt="img Product" />
											<p className="text-body text-center">{item.Descripcion}</p>
											<div className="col-12 ">
												<p className=''> SKU:
													<p className="mb-0" style={{ fontSize: "15" }}>{`${item.Alias}`}</p>
												</p>
												<p className=''> Valor: <br />
													<span className='componente__aliasValor' >
														ARS $  {`${(new Intl.NumberFormat().format(item.Precio))}`}<br />
													</span>
												</p>
											</div>
											{item.Tipo === 'ram' && listado[paso].find(art => art.Alias === item.Alias) && //CONTADOR DE RAM
												<div className='addRemove'>
													<div className='centrar__botones'>
														<button className='remove' onClick={() => handleRestarCantidadPrecio(item)}>
															<i className="fa-solid fa-minus"></i>
														</button>
														{counterRam}
														<button className='add' onClick={() => handleSumarCantidadPrecio(item)}>
															<i className="fa-solid fa-plus"></i>
														</button>
													</div>
												</div>
											}
											{
												paso !== 'ram' ? '' : listado.ram.length > 0
													&&
													listado.ram[0].Alias === item.Alias
													&&
													widthMin
													&&
													<div className='botones__sgte__ant fade-in d-flex justify-content-end m-3'>
														<button onClick={() => cambiarPaso(listado[paso][0])}>
															SIGUIENTE
															<div className="arrow-wrapper">
																<div className="arrow__right"></div>
															</div>
														</button>
													</div>
											}
										</div>
										{(!listado[paso].some(art => art.Alias === item.Alias) && paso === "fuente") || (paso === "motherboard" && listadoAgregados.length > 0 && listadoAgregados[0].Tags === item.Tags) ?
											<button onClick={() => {
												const handleCli = handleClick(paso, item);
												handleCli()
											}} className="cardComponents-button buttonBuyItNowPcComponents">Elegir</button> :
											<button onClick={() => handleClick(paso, item)} className="cardComponents-button buttonBuyItNowPcComponents" >Elegir</button>
										}
										{paso !== 'ram'
											? ''
											: listado.ram.length > 0 && !widthMin
												?
												<div>
													<button className='cardComponents-button buttonBuyItNowPcComponents' onClick={() => cambiarPaso(listado[paso][0])}>
														Elegir
													</button>
												</div>
												: ''
										}
									</div>
								</div>
							}
						</>
					)) : <p>No hay coincidencias.</p>
					:

					paso === "fuente" && listadoAgregados.length > 0 ? (
						filteredProducts.length > 0 ? (
							filteredProducts.filter(item => item.Voltaje > listadoAgregados[5].Voltaje).map((item) => (
								<>

									<div className="col-xl-3  col-8">
										<div className="cardComponents my-4" key={item.id}>
											<div className="cardComponents-details">
												{listado[paso].find(art => art.Alias === item.Alias) &&
													<OverlayTrigger placement="top" overlay={renderTooltip}>
														<motion.i whileHover={{ scale: 1.1 }}
															whileTap={{ scale: 0.9 }} className="descliquearComponente text-align-end"
														>
															<MdDeleteForever aria-hidden="true"
																cursor='pointer'
																color='#EE4245'
																size='2.5em'
																onClick={() => handleRemoveItem(item)} />
														</motion.i>
													</OverlayTrigger>
												}
												<img src={item.Imagen} className="btn-verMas img-componente" alt="img Product" />
												<p className="text-body text-center">{item.Descripcion}</p>
												<div className="col-12 ">
													<p className=''> SKU:
														<p className="mb-0" style={{ fontSize: "15" }}>{`${item.Alias}`}</p>
													</p>
													<p className=''> Valor: <br />
														<span className='componente__aliasValor' >
															ARS $  {`${(new Intl.NumberFormat().format(item.Precio))}`}<br />
														</span>
													</p>
												</div>
												{item.Tipo === 'ram' && listado[paso].find(art => art.Alias === item.Alias) && //CONTADOR DE RAM
													<div className='addRemove'>
														<div className='centrar__botones'>
															<button className='remove' onClick={() => handleRestarCantidadPrecio(item)}>
																<i className="fa-solid fa-minus"></i>
															</button>
															{counterRam}
															<button className='add' onClick={() => handleSumarCantidadPrecio(item)}>
																<i className="fa-solid fa-plus"></i>
															</button>
														</div>
													</div>
												}
												{
													paso !== 'ram' ? '' : listado.ram.length > 0
														&&
														listado.ram[0].Alias === item.Alias
														&&
														widthMin
														&&
														<div className='botones__sgte__ant fade-in d-flex justify-content-end m-3'>
															<button onClick={() => cambiarPaso(listado[paso][0])}>
																SIGUIENTE
																<div className="arrow-wrapper">
																	<div className="arrow__right"></div>
																</div>
															</button>
														</div>
												}
											</div>
											{(!listado[paso].some(art => art.Alias === item.Alias) && paso === "fuente") || (paso === "motherboard" && listadoAgregados.length > 0 && listadoAgregados[0].Tags === item.Tags) ?
												<button onClick={() => {
													const handleCli = handleClick(paso, item);
													handleCli()
												}} className="cardComponents-button buttonBuyItNowPcComponents">Elegir</button> :
												<button onClick={() => handleClick(paso, item)} className="cardComponents-button buttonBuyItNowPcComponents" >Elegir</button>
											}
											{paso !== 'ram'
												? ''
												: listado.ram.length > 0 && !widthMin
													?
													<div>
														<button className='cardComponents-button buttonBuyItNowPcComponents' onClick={() => cambiarPaso(listado[paso][0])}>
															Elegir
														</button>
													</div>
													: ''
											}
										</div>
									</div>
								</>
							))
						) : (
							<p>No hay coincidencias.</p>
						)
					) :
						paso === "cooler" && listadoAgregados.length > 0 ? (
							filteredProducts.length > 0 ? (
								filteredProducts.filter(item => {
									if (coolerIncluido) {
										return true;
									} else {
										return item.Precio > 0;
									}
								}).map((item) => (
									<>
										<div className="col-xl-3 col-8">
											<div className="cardComponents my-4" key={item.id}>
												<div className="cardComponents-details">
													{listado[paso].find(art => art.Alias === item.Alias) &&
														item.Precio > 0 && (
															<OverlayTrigger placement="top" overlay={renderTooltip}>
																<motion.i whileHover={{ scale: 1.1 }}
																	whileTap={{ scale: 0.9 }} className="descliquearComponente text-align-end"
																>
																	<MdDeleteForever aria-hidden="true"
																		cursor='pointer'
																		color='#EE4245'
																		size='2.5em'
																		onClick={() => handleRemoveItem(item)} />
																</motion.i>
															</OverlayTrigger>
														)
													}
													<img src={item.Imagen} className="btn-verMas img-componente" alt="img Product" />
													<p className="text-body text-center">{item.Descripcion}</p>
													<div className="col-12 ">
														<p className=''> SKU:
															<p className="mb-0" style={{ fontSize: "15" }}>{`${item.Alias}`}</p>
														</p>
														{item.Precio > 0 && (
															<p className=''> Valor: <br />
																<span className='componente__aliasValor' >
																	ARS $  {`${(new Intl.NumberFormat().format(item.Precio))}`}<br />
																</span>
															</p>
														)}
													</div>
													{item.Tipo === 'ram' && listado[paso].find(art => art.Alias === item.Alias) && //CONTADOR DE RAM
														<div className='addRemove'>
															<div className='centrar__botones'>
																<button className='remove' onClick={() => handleRestarCantidadPrecio(item)}>
																	<i className="fa-solid fa-minus"></i>
																</button>
																{counterRam}
																<button className='add' onClick={() => handleSumarCantidadPrecio(item)}>
																	<i className="fa-solid fa-plus"></i>
																</button>
															</div>
														</div>
													}
													{
														paso !== 'ram' ? '' : listado.ram.length > 0
															&&
															listado.ram[0].Alias === item.Alias
															&&
															widthMin
															&&
															<div className='botones__sgte__ant fade-in d-flex justify-content-end m-3'>
																<button onClick={() => cambiarPaso(listado[paso][0])}>
																	SIGUIENTE
																	<div className="arrow-wrapper">
																		<div className="arrow__right"></div>
																	</div>
																</button>
															</div>
													}
												</div>
												{(!listado[paso].some(art => art.Alias === item.Alias) && paso === "fuente") || (paso === "motherboard" && listadoAgregados.length > 0 && listadoAgregados[0].Tags === item.Tags) ?
													<button onClick={() => {
														const handleCli = handleClick(paso, item);
														handleCli()
													}} className="cardComponents-button buttonBuyItNowPcComponents">Elegir</button> :
													<button onClick={() => handleClick(paso, item)} className="cardComponents-button buttonBuyItNowPcComponents" >Elegir</button>
												}
												{paso !== 'ram'
													? ''
													: listado.ram.length > 0 && !widthMin
														?
														<div>
															<button className='cardComponents-button buttonBuyItNowPcComponents' onClick={() => cambiarPaso(listado[paso][0])}>
																Elegir
															</button>
														</div>
														: ''
												}
											</div>
										</div>
									</>
								))
							) : (
								<p>No hay coincidencias.</p>
							)
						) :
							filteredProducts.length > 0 ? filteredProducts.map((item) => (
								<>
									<div className="col-xl-3 col-8">
										<div className="cardComponents my-4" key={item.id}>
											<div className="cardComponents-details">
												{listado[paso].find(art => art.Alias === item.Alias) &&
													item.Precio > 0 && (
														<OverlayTrigger placement="top" overlay={renderTooltip}>
															<motion.i whileHover={{ scale: 1.1 }}
																whileTap={{ scale: 0.9 }} className="descliquearComponente text-align-end"
															>
																<MdDeleteForever aria-hidden="true"
																	cursor='pointer'
																	color='#EE4245'
																	size='2.5em'
																	onClick={() => handleRemoveItem(item)} />
															</motion.i>
														</OverlayTrigger>
													)
												}
												<img src={item.Imagen} className="btn-verMas img-componente" alt="img Product" />
												<p className="text-body text-center">{item.Descripcion}</p>
												<div className="col-12 ">
													<p className=''> SKU:
														<p className="mb-0" style={{ fontSize: "15" }}>{`${item.Alias}`}</p>
													</p>
													{item.Precio > 0 && (
														<p className=''> Valor: <br />
															<span className='componente__aliasValor' >
																ARS $  {`${(new Intl.NumberFormat().format(item.Precio))}`}<br />
															</span>
														</p>
													)}
												</div>
												{item.Tipo === 'ram' && listado[paso].find(art => art.Alias === item.Alias) && //CONTADOR DE RAM
													<div className='addRemove'>
														<div className='centrar__botones'>
															<button className='remove' onClick={() => handleRestarCantidadPrecio(item)}>
																<i className="fa-solid fa-minus"></i>
															</button>
															{counterRam}
															<button className='add' onClick={() => handleSumarCantidadPrecio(item)}>
																<i className="fa-solid fa-plus"></i>
															</button>
														</div>
													</div>
												}
												{
													paso !== 'ram' ? '' : listado.ram.length > 0
														&&
														listado.ram[0].Alias === item.Alias
														&&
														widthMin
														&&
														<div className='botones__sgte__ant fade-in d-flex justify-content-end m-3'>
															<button onClick={() => cambiarPaso(listado[paso][0])}>
																SIGUIENTE
																<div className="arrow-wrapper">
																	<div className="arrow__right"></div>
																</div>
															</button>
														</div>
												}
											</div>
											{(!listado[paso].some(art => art.Alias === item.Alias) && paso === "fuente") || (paso === "motherboard" && listadoAgregados.length > 0 && listadoAgregados[0].Tags === item.Tags) ?
												<button onClick={() => {
													const handleCli = handleClick(paso, item);
													const alerta = alertaFinalizacionCompra()
													handleCli()
													alerta()
												}} className="cardComponents-button buttonBuyItNowPcComponents">Elegir</button> :
												<button onClick={() => handleClick(paso, item)} className="cardComponents-button buttonBuyItNowPcComponents" >Elegir</button>
											}
											{paso !== 'ram' //CAMBIO DE PASO: A MEJORAR
												? ''
												: listado.ram.length > 0 && !widthMin
													?
													<div>
														<button className='cardComponents-button buttonBuyItNowPcComponents' onClick={() => cambiarPaso(listado[paso][0])}>
															Elegir
														</button>
													</div>
													: ''
											}
										</div>
									</div>
								</>
							)) : <p>No hay coincidencias.</p>
				}

			</div>}


			<Toaster />
		</>
	)
}

export default MapeoComponentes;

// /* eslint-disable jsx-a11y/anchor-is-valid */
// import './ConfiguradorColumnasStyle.css';

// import { OverlayTrigger, Tooltip } from 'react-bootstrap';
// import React, { useContext } from 'react'

// import { GrPowerReset } from "react-icons/gr";
// import { IoMdArrowRoundBack } from "react-icons/io";
// import { ListadoContext } from '../ContextConfigurador/ListadoContext';
// import { MarcaProcesadorContext } from '../ContextConfigurador/MarcaProcesadorContext';
// import { MdDeleteForever } from "react-icons/md";
// import { PreconfiguradoContext } from '../ContextConfigurador/PreconfiguradoContext';
// import Spinner from '../../../Spinner/Spinner';
// import Swal from 'sweetalert2';
// import { motion } from "framer-motion";
// import { useNavigate } from 'react-router-dom';
// import { useParams } from 'react-router-dom';
// import toast, { Toaster } from 'react-hot-toast';
// import { nanoid } from 'nanoid';

// const MapeoComponentes = () => {
// 	const { componentes, paso, infoDidMount, busqueda, setBusqueda, searchInput, loading } = useContext(MarcaProcesadorContext);
// 	const { addComponentList, listado, removeComponentList, typeDetalle } = useContext(ListadoContext);
// 	const { sumaPrecios, restaPrecios, counterRam, setCounterRam, counterDisco, setCounterDisco } = useContext(PreconfiguradoContext);
// 	const navigate = useNavigate();
// 	const { param } = useParams();

// 	// Tooltips para probar botones de back y reset
// 	const renderTooltip = props => (
// 		<Tooltip {...props}>Quitar producto</Tooltip>
// 	);
// 	const toolTipBack = props => (
// 		<Tooltip {...props}>Anterior componente</Tooltip>
// 	);
// 	const tooltipReset = props => (
// 		<Tooltip {...props}>Reiniciar configuracion</Tooltip>
// 	);

// 	// Resetear configuracion vuelve a armar de cero

// 	const reset = () => {
// 		window.location.replace('');
// 	}

// 	let aComponentesAgregados = [];
// 	typeDetalle.forEach(element => {
// 		if (listado[element].length !== 0) {
// 			aComponentesAgregados.push(listado[element]);
// 		}
// 	});
// 	let listadoAgregados = []
// 	aComponentesAgregados.forEach(element => {
// 		element.forEach(item => {
// 			listadoAgregados.push(item);
// 		})
// 	});

// 	// Declaro una variable para verificar si cooler esta incluido en la configuracion

// 	const coolerIncluido = listadoAgregados.length > 0 &&
// 		listadoAgregados[0].CoolerStock &&
// 		listadoAgregados[0].CoolerStock.toLowerCase().includes("si");

// 	console.log(coolerIncluido, "cooler incluye")

// 	console.log(listadoAgregados, "este es el elistadoagregados")

// 	// ---------------------------------------Lógica de filtrado de productos----------------------------------------------------------

// 	const filteredProducts = componentes.filter(product =>
// 		product.Descripcion.toLowerCase().includes(searchInput)
// 	)

// 	const handleOnChange = (e) => {
// 		setBusqueda(e.target.value);
// 	}

// 	// Refactor de handler para retornar a paso anterior 

// 	const handleBackPaso = (index) => {
// 		if (paso === "cooler") {
// 			infoDidMount(typeDetalle[index - 1]);
// 		} else {
// 			infoDidMount(typeDetalle[index]);
// 		}
// 	}


// 	function getBackPasoValue(paso) {
// 		const pasos = ['motherboard', 'ram', 'disco', 'gabinete', 'video', 'fuente', 'armado', 'cooler', 'licencia', 'monitor', 'teclado', 'mouse', 'auricular', 'silla'];
// 		return pasos.indexOf(paso);
// 	}

// 	// Alerta de aviso para finalizar compra una vez que llegamos a determinado paso

// 	const alertaFinalizacionCompra = () => {
// 		const idPedido = nanoid(10)
// 		sessionStorage.setItem("idPedido", idPedido)
// 		Swal.fire({
// 			html: '<h3><b>Ya seleccionaste todos los items necesarios para armar tu pc.</b></h3> <br> <h5>Ahora podes elegir tus perifericos y componentes adicionales para tu pc gamer o bien terminar tu compra.</h5>',
// 			showCancelButton: true,
// 			confirmButtonText: 'Seguir comprando!',
// 			cancelButtonText: 'Finalizar compra.',
// 			reverseButtons: true
// 		}).then((result) => {
// 			if (result.isConfirmed) {
// 			} else {
// 				navigate(`/Order/${param}`);
// 			}
// 		});
// 	}

// 	//---------------------------------------------------------------------------------------------------------------------------------
// 	const handleClick = (paso, item) => {
// 		sumaPrecios(item.Precio, listado[paso], item.Alias, counterRam)//PASO ALIAS COMO PARAMETRO
// 		addComponentList(paso, item)
// 		console.log(item)

// 		//PASO AL SIGUIENTE COMPONENTE
// 		let indexComponenteElegido = typeDetalle.indexOf(item.Tipo)
// 		let proximoComponente = paso === "fuente" ? indexComponenteElegido + 2 : indexComponenteElegido + 1; // Salteo paso armado
// 		console.log(componentes)
// 		console.log(indexComponenteElegido)
// 		console.log(proximoComponente)
// 		console.log(typeDetalle[proximoComponente])
// 		if (paso !== "ram") {
// 			toast.success(item.Descripcion + " agregado a tu configuración ", {
// 				position: 'top-right'
// 			})
// 		} else {
// 			toast.success(item.Descripcion + " agregado, puedes seleccionar cantidad de Slots en este paso. ", {
// 				position: 'top-right',
// 				duration: 4000
// 			});

// 		}

// 		if (paso === "fuente" && listadoAgregados.length > 0 && coolerIncluido) {
// 			toast.success(
// 				<span style={{ fontSize: '20px', textAlign: 'center' }}>
// 					<b>El microprocesador que elegiste en el primer paso incluye Cooler. Si deseas, puedes agregarle otro.</b>
// 				</span>,
// 				{
// 					position: 'top-right',
// 					duration: 7000
// 				}
// 			);
// 		}

// 		setBusqueda('')

// 		if (proximoComponente <= 14 && paso !== 'ram'/* && paso !== 'disco' */) { //DESCOMENTO ESTO --->( && paso !== 'disco') PARA USAR CONTADOR
// 			infoDidMount(typeDetalle[proximoComponente])
// 			if (paso === 'cooler' && listadoAgregados.length > 0) {
// 				alertaFinalizacionCompra();
// 			}

// 		} else if (proximoComponente <= 13 && paso === 'ram') {
// 			setCounterRam(1)

// 		} else if (proximoComponente <= 13 && paso === 'disco') {
// 			setCounterDisco(1)
// 		}
// 	}



// 	const handleSumarCantidadPrecio = (componente) => {
// 		let esDisco = componente.Tipo === 'disco';
// 		if (listadoAgregados[1]) {
// 			if (!esDisco && counterRam < listadoAgregados[1].Slots) {
// 				let precio = listado[paso][0].Precio;
// 				setCounterRam(counterRam + 1);
// 				sumaPrecios(precio, listado[paso], componente, counterRam);
// 			} else if (esDisco && counterDisco < listadoAgregados[1].Slots) {
// 				let precio = listado[paso][0].Precio;
// 				setCounterDisco(counterDisco + 1);
// 				sumaPrecios(precio, listado[paso], componente, counterDisco);
// 			}
// 		} else {
// 			console.log("No se pudo encontrar la cantidad de slots disponibles.");
// 		}
// 	};




// 	const handleRestarCantidadPrecio = (item) => { //RESTAR CANTIDAD EN RAMS Y DISCOS
// 		let esDisco = item.Tipo === 'disco';

// 		if (!esDisco && counterRam > 1) {
// 			setCounterRam(counterRam - 1)
// 			restaPrecios(item, listado[paso], counterRam)

// 		} else if (esDisco && counterDisco > 1) {
// 			setCounterDisco(counterDisco - 1)
// 			restaPrecios(item, listado[paso], counterDisco)
// 		}
// 	}

// 	// Esta funcion es la que se usa para pasar al siguiente componente
// 	const cambiarPaso = (item) => {
// 		let indexComponenteElegido = typeDetalle.indexOf(item.Tipo)
// 		let proximoComponente = indexComponenteElegido + 1;
// 		console.log(indexComponenteElegido)
// 		console.log(proximoComponente)
// 		proximoComponente <= 13 && infoDidMount(typeDetalle[proximoComponente])
// 		console.log(proximoComponente)
// 		setBusqueda('')
// 	}

// 	const handleRemoveItem = (item) => {
// 		if (item.Tipo === 'ram') {
// 			restaPrecios(item, listado[paso][0], counterRam)
// 		} else if (item.Tipo === 'disco') {
// 			restaPrecios(item, listado[paso][0], counterDisco)
// 		} else {
// 			restaPrecios(item)
// 		}

// 		removeComponentList(item)
// 		toast.error(item.Descripcion + " quitado de tu configuracion.", {
// 			position: 'top-right'
// 		})
// 	}
// 	let widthMin = window.innerWidth < 768
// 	return (
// 		<>

// 			<div class="group-input-filtered">
// 				<svg class="icon-filtered" aria-hidden="true" viewBox="0 0 24 24"><g><path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path></g></svg>
// 				<input value={busqueda} onChange={handleOnChange} placeholder="Buscá por palabra clave..." type="search" class="input-filtered" />
// 			</div>

// 			<div className='botones__sgte__ant d-flex  m-3' style={{ cursor: 'pointer' }} id='resetButtons'>
// 				{paso !== "microprocesador" && (
// 					<>
// 						<a onClick={() => handleBackPaso(getBackPasoValue(paso))}>
// 							<OverlayTrigger placement="left" overlay={toolTipBack}>
// 								{/* <i> */}
// 								<p>ANTERIOR</p>
// 								{/* <IoMdArrowRoundBack size='1.5em' cursor='pointer' color='#1e6dff' /> */}
// 								{/* </i> */}
// 							</OverlayTrigger>
// 						</a>
// 						<a onClick={() => reset()} style={{ marginLeft: "30px" }}>
// 							<OverlayTrigger placement="right" overlay={tooltipReset}>
// 								{/* <i> */}
// 								<p>REINICIAR</p>
// 								{/* <GrPowerReset size='1.5em' cursor='pointer' color='#1e6dff' /> */}
// 								{/* </i> */}
// 							</OverlayTrigger>
// 						</a>
// 					</>
// 				)}
// 			</div>
// 			<h5 className='confEleg'>NSX {'>'} {paso.toUpperCase()}</h5>

// 			{paso === 'disco' && listado.disco.length > 0 && (
// 				<div className='botones__sgte__ant fade-in d-flex justify-content-end m-3'>
// 					<button onClick={() => cambiarPaso(listado[paso][0])}>
// 						SIGUIENTE
// 						<div className="arrow-wrapper">
// 							<div className="arrow__right"></div>
// 						</div>
// 					</button>
// 				</div>
// 			)}

// 			{loading ? <Spinner /> : <div className={`${!widthMin && 'row'} contenedorComponentes`}>
// 				{paso === "motherboard" && listadoAgregados.length > 0 ?
// 					filteredProducts.length > 0 ? filteredProducts.map((item) => (
// 						<>
// 							{listadoAgregados[0].Tags === item.Tags &&
// 								<div className="col-xl-3  col-8">
// 									<div className="cardComponents my-4" key={item.id}>
// 										<div className="cardComponents-details">
// 											{listado[paso].find(art => art.Alias === item.Alias) &&
// 												<OverlayTrigger placement="top" overlay={renderTooltip}>
// 													<motion.i whileHover={{ scale: 1.1 }}
// 														whileTap={{ scale: 0.9 }} className="descliquearComponente text-align-end"
// 													>
// 														<MdDeleteForever aria-hidden="true"
// 															cursor='pointer'
// 															color='#EE4245'
// 															size='2.5em'
// 															onClick={() => handleRemoveItem(item)} />
// 													</motion.i>
// 												</OverlayTrigger>
// 											}
// 											<img src={item.Imagen} className="btn-verMas img-componente" alt="img Product" />
// 											<p className="text-body text-center">{item.Descripcion}</p>
// 											<div className="col-12 ">
// 												<p className=''> SKU:
// 													<p className="mb-0" style={{ fontSize: "15" }}>{`${item.Alias}`}</p>
// 												</p>
// 												<p className=''> Valor: <br />
// 													<span className='componente__aliasValor' >
// 														ARS $  {`${(new Intl.NumberFormat().format(item.Precio))},00`}<br />
// 													</span>
// 												</p>
// 											</div>
// 											{item.Tipo === 'ram' && listado[paso].find(art => art.Alias === item.Alias) && //CONTADOR DE RAM
// 												<div className='addRemove'>
// 													<div className='centrar__botones'>
// 														<button className='remove' onClick={() => handleRestarCantidadPrecio(item)}>
// 															<i className="fa-solid fa-minus"></i>
// 														</button>
// 														{counterRam}
// 														<button className='add' onClick={() => handleSumarCantidadPrecio(item)}>
// 															<i className="fa-solid fa-plus"></i>
// 														</button>
// 													</div>
// 												</div>
// 											}
// 											{
// 												paso !== 'ram' ? '' : listado.ram.length > 0
// 													&&
// 													listado.ram[0].Alias === item.Alias
// 													&&
// 													widthMin
// 													&&
// 													<div className='botones__sgte__ant fade-in d-flex justify-content-end m-3'>
// 														<button onClick={() => cambiarPaso(listado[paso][0])}>
// 															SIGUIENTE
// 															<div className="arrow-wrapper">
// 																<div className="arrow__right"></div>
// 															</div>
// 														</button>
// 													</div>
// 											}
// 										</div>
// 										{(!listado[paso].some(art => art.Alias === item.Alias) && paso === "fuente") || (paso === "motherboard" && listadoAgregados.length > 0 && listadoAgregados[0].Tags === item.Tags) ?
// 											<button onClick={() => {
// 												const handleCli = handleClick(paso, item);
// 												handleCli()
// 											}} className="cardComponents-button buttonBuyItNowPcComponents">Elegir</button> :
// 											<button onClick={() => handleClick(paso, item)} className="cardComponents-button buttonBuyItNowPcComponents" >Elegir</button>
// 										}
// 										{paso !== 'ram'
// 											? ''
// 											: listado.ram.length > 0 && !widthMin
// 												?
// 												<div>
// 													<button className='cardComponents-button buttonBuyItNowPcComponents' onClick={() => cambiarPaso(listado[paso][0])}>
// 														Elegir
// 													</button>
// 												</div>
// 												: ''
// 										}
// 									</div>
// 								</div>
// 							}
// 						</>
// 					)) : <p>No hay coincidencias.</p>
// 					:

// 					paso === "fuente" && listadoAgregados.length > 0 ? (
// 						filteredProducts.length > 0 ? (
// 							filteredProducts.filter(item => item.Voltaje > listadoAgregados[5].Voltaje).map((item) => (
// 								<>

// 									<div className="col-xl-3  col-8">
// 										<div className="cardComponents my-4" key={item.id}>
// 											<div className="cardComponents-details">
// 												{listado[paso].find(art => art.Alias === item.Alias) &&
// 													<OverlayTrigger placement="top" overlay={renderTooltip}>
// 														<motion.i whileHover={{ scale: 1.1 }}
// 															whileTap={{ scale: 0.9 }} className="descliquearComponente text-align-end"
// 														>
// 															<MdDeleteForever aria-hidden="true"
// 																cursor='pointer'
// 																color='#EE4245'
// 																size='2.5em'
// 																onClick={() => handleRemoveItem(item)} />
// 														</motion.i>
// 													</OverlayTrigger>
// 												}
// 												<img src={item.Imagen} className="btn-verMas img-componente" alt="img Product" />
// 												<p className="text-body text-center">{item.Descripcion}</p>
// 												<div className="col-12 ">
// 													<p className=''> SKU:
// 														<p className="mb-0" style={{ fontSize: "15" }}>{`${item.Alias}`}</p>
// 													</p>
// 													<p className=''> Valor: <br />
// 														<span className='componente__aliasValor' >
// 															ARS $  {`${(new Intl.NumberFormat().format(item.Precio))},00`}<br />
// 														</span>
// 													</p>
// 												</div>
// 												{item.Tipo === 'ram' && listado[paso].find(art => art.Alias === item.Alias) && //CONTADOR DE RAM
// 													<div className='addRemove'>
// 														<div className='centrar__botones'>
// 															<button className='remove' onClick={() => handleRestarCantidadPrecio(item)}>
// 																<i className="fa-solid fa-minus"></i>
// 															</button>
// 															{counterRam}
// 															<button className='add' onClick={() => handleSumarCantidadPrecio(item)}>
// 																<i className="fa-solid fa-plus"></i>
// 															</button>
// 														</div>
// 													</div>
// 												}
// 												{
// 													paso !== 'ram' ? '' : listado.ram.length > 0
// 														&&
// 														listado.ram[0].Alias === item.Alias
// 														&&
// 														widthMin
// 														&&
// 														<div className='botones__sgte__ant fade-in d-flex justify-content-end m-3'>
// 															<button onClick={() => cambiarPaso(listado[paso][0])}>
// 																SIGUIENTE
// 																<div className="arrow-wrapper">
// 																	<div className="arrow__right"></div>
// 																</div>
// 															</button>
// 														</div>
// 												}
// 											</div>
// 											{(!listado[paso].some(art => art.Alias === item.Alias) && paso === "fuente") || (paso === "motherboard" && listadoAgregados.length > 0 && listadoAgregados[0].Tags === item.Tags) ?
// 												<button onClick={() => {
// 													const handleCli = handleClick(paso, item);
// 													handleCli()
// 												}} className="cardComponents-button buttonBuyItNowPcComponents">Elegir</button> :
// 												<button onClick={() => handleClick(paso, item)} className="cardComponents-button buttonBuyItNowPcComponents" >Elegir</button>
// 											}
// 											{paso !== 'ram'
// 												? ''
// 												: listado.ram.length > 0 && !widthMin
// 													?
// 													<div>
// 														<button className='cardComponents-button buttonBuyItNowPcComponents' onClick={() => cambiarPaso(listado[paso][0])}>
// 															Elegir
// 														</button>
// 													</div>
// 													: ''
// 											}
// 										</div>
// 									</div>
// 								</>
// 							))
// 						) : (
// 							<p>No hay coincidencias.</p>
// 						)
// 					) :
// 						paso === "cooler" && listadoAgregados.length > 0 ? (
// 							filteredProducts.length > 0 ? (
// 								filteredProducts.filter(item => {
// 									if (coolerIncluido) {
// 										return true;
// 									} else {
// 										return item.Precio > 0;
// 									}
// 								}).map((item) => (
// 									<>
// 										<div className="col-xl-3 col-8">
// 											<div className="cardComponents my-4" key={item.id}>
// 												<div className="cardComponents-details">
// 													{listado[paso].find(art => art.Alias === item.Alias) &&
// 														item.Precio > 0 && (
// 															<OverlayTrigger placement="top" overlay={renderTooltip}>
// 																<motion.i whileHover={{ scale: 1.1 }}
// 																	whileTap={{ scale: 0.9 }} className="descliquearComponente text-align-end"
// 																>
// 																	<MdDeleteForever aria-hidden="true"
// 																		cursor='pointer'
// 																		color='#EE4245'
// 																		size='2.5em'
// 																		onClick={() => handleRemoveItem(item)} />
// 																</motion.i>
// 															</OverlayTrigger>
// 														)
// 													}
// 													<img src={item.Imagen} className="btn-verMas img-componente" alt="img Product" />
// 													<p className="text-body text-center">{item.Descripcion}</p>
// 													<div className="col-12 ">
// 														<p className=''> SKU:
// 															<p className="mb-0" style={{ fontSize: "15" }}>{`${item.Alias}`}</p>
// 														</p>
// 														{item.Precio > 0 && (
// 															<p className=''> Valor: <br />
// 																<span className='componente__aliasValor' >
// 																	ARS $  {`${(new Intl.NumberFormat().format(item.Precio))},00`}<br />
// 																</span>
// 															</p>
// 														)}
// 													</div>
// 													{item.Tipo === 'ram' && listado[paso].find(art => art.Alias === item.Alias) && //CONTADOR DE RAM
// 														<div className='addRemove'>
// 															<div className='centrar__botones'>
// 																<button className='remove' onClick={() => handleRestarCantidadPrecio(item)}>
// 																	<i className="fa-solid fa-minus"></i>
// 																</button>
// 																{counterRam}
// 																<button className='add' onClick={() => handleSumarCantidadPrecio(item)}>
// 																	<i className="fa-solid fa-plus"></i>
// 																</button>
// 															</div>
// 														</div>
// 													}
// 													{
// 														paso !== 'ram' ? '' : listado.ram.length > 0
// 															&&
// 															listado.ram[0].Alias === item.Alias
// 															&&
// 															widthMin
// 															&&
// 															<div className='botones__sgte__ant fade-in d-flex justify-content-end m-3'>
// 																<button onClick={() => cambiarPaso(listado[paso][0])}>
// 																	SIGUIENTE
// 																	<div className="arrow-wrapper">
// 																		<div className="arrow__right"></div>
// 																	</div>
// 																</button>
// 															</div>
// 													}
// 												</div>
// 												{(!listado[paso].some(art => art.Alias === item.Alias) && paso === "fuente") || (paso === "motherboard" && listadoAgregados.length > 0 && listadoAgregados[0].Tags === item.Tags) ?
// 													<button onClick={() => {
// 														const handleCli = handleClick(paso, item);
// 														handleCli()
// 													}} className="cardComponents-button buttonBuyItNowPcComponents">Elegir</button> :
// 													<button onClick={() => handleClick(paso, item)} className="cardComponents-button buttonBuyItNowPcComponents" >Elegir</button>
// 												}
// 												{paso !== 'ram'
// 													? ''
// 													: listado.ram.length > 0 && !widthMin
// 														?
// 														<div>
// 															<button className='cardComponents-button buttonBuyItNowPcComponents' onClick={() => cambiarPaso(listado[paso][0])}>
// 																Elegir
// 															</button>
// 														</div>
// 														: ''
// 												}
// 											</div>
// 										</div>
// 									</>
// 								))
// 							) : (
// 								<p>No hay coincidencias.</p>
// 							)
// 						) :
// 							filteredProducts.length > 0 ? filteredProducts.map((item) => (
// 								<>
// 									<div className="col-xl-3 col-8">
// 										<div className="cardComponents my-4" key={item.id}>
// 											<div className="cardComponents-details">
// 												{listado[paso].find(art => art.Alias === item.Alias) &&
// 													item.Precio > 0 && (
// 														<OverlayTrigger placement="top" overlay={renderTooltip}>
// 															<motion.i whileHover={{ scale: 1.1 }}
// 																whileTap={{ scale: 0.9 }} className="descliquearComponente text-align-end"
// 															>
// 																<MdDeleteForever aria-hidden="true"
// 																	cursor='pointer'
// 																	color='#EE4245'
// 																	size='2.5em'
// 																	onClick={() => handleRemoveItem(item)} />
// 															</motion.i>
// 														</OverlayTrigger>
// 													)
// 												}
// 												<img src={item.Imagen} className="btn-verMas img-componente" alt="img Product" />
// 												<p className="text-body text-center">{item.Descripcion}</p>
// 												<div className="col-12 ">
// 													<p className=''> SKU:
// 														<p className="mb-0" style={{ fontSize: "15" }}>{`${item.Alias}`}</p>
// 													</p>
// 													{item.Precio > 0 && (
// 														<p className=''> Valor: <br />
// 															<span className='componente__aliasValor' >
// 																ARS $  {`${(new Intl.NumberFormat().format(item.Precio))},00`}<br />
// 															</span>
// 														</p>
// 													)}
// 												</div>
// 												{item.Tipo === 'ram' && listado[paso].find(art => art.Alias === item.Alias) && //CONTADOR DE RAM
// 													<div className='addRemove'>
// 														<div className='centrar__botones'>
// 															<button className='remove' onClick={() => handleRestarCantidadPrecio(item)}>
// 																<i className="fa-solid fa-minus"></i>
// 															</button>
// 															{counterRam}
// 															<button className='add' onClick={() => handleSumarCantidadPrecio(item)}>
// 																<i className="fa-solid fa-plus"></i>
// 															</button>
// 														</div>
// 													</div>
// 												}
// 												{
// 													paso !== 'ram' ? '' : listado.ram.length > 0
// 														&&
// 														listado.ram[0].Alias === item.Alias
// 														&&
// 														widthMin
// 														&&
// 														<div className='botones__sgte__ant fade-in d-flex justify-content-end m-3'>
// 															<button onClick={() => cambiarPaso(listado[paso][0])}>
// 																SIGUIENTE
// 																<div className="arrow-wrapper">
// 																	<div className="arrow__right"></div>
// 																</div>
// 															</button>
// 														</div>
// 												}
// 											</div>
// 											{(!listado[paso].some(art => art.Alias === item.Alias) && paso === "fuente") || (paso === "motherboard" && listadoAgregados.length > 0 && listadoAgregados[0].Tags === item.Tags) ?
// 												<button onClick={() => {
// 													const handleCli = handleClick(paso, item);
// 													const alerta = alertaFinalizacionCompra()
// 													handleCli()
// 													alerta()
// 												}} className="cardComponents-button buttonBuyItNowPcComponents">Elegir</button> :
// 												<button onClick={() => handleClick(paso, item)} className="cardComponents-button buttonBuyItNowPcComponents" >Elegir</button>
// 											}
// 											{paso !== 'ram' //CAMBIO DE PASO: A MEJORAR
// 												? ''
// 												: listado.ram.length > 0 && !widthMin
// 													?
// 													<div>
// 														<button className='cardComponents-button buttonBuyItNowPcComponents' onClick={() => cambiarPaso(listado[paso][0])}>
// 															Elegir
// 														</button>
// 													</div>
// 													: ''
// 											}
// 										</div>
// 									</div>
// 								</>
// 							)) : <p>No hay coincidencias.</p>
// 				}

// 			</div>}


// 			<Toaster />
// 		</>
// 	)
// }

// export default MapeoComponentes;