import React from 'react'
import './PostPedido.css'
import BtnInicio from './BtnInicio'

const PostCompra = () => {
  return (
    <div className='container d-flex justify-content-center align-items-center flex-column my-5 contenedorGraciasPedido'>
      <div className='mb-5'>
        <h1>GRACIAS!</h1><br />
        <h2>
          Su pedido fue registrado correctamente! <br />
          Nos contactaremos a la brevedad para coordinar metodo de pago y envio o retiro en sucursal!
        </h2>
      </div>
      <BtnInicio />
    </div>
  )
}

export default PostCompra