export const AllProducts = [
    {
        id: "Pc Oficina Nsx Core I5 10400 Ram 8gb Ssd 512gb Gabinete Kit",
        productName: "Pc Oficina Nsx Core I5 10400 Ram 8gb Ssd 512gb Gabinete Kit",
        category:'Pc',
        img: "https://http2.mlstatic.com/D_NQ_NP_832360-MLA52142132677_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1214333627-pc-oficina-nsx-core-i5-10400-ram-8gb-ssd-512gb-gabinete-kit-_JM#position=18&search_layout=stack&type=item&tracking_id=7a66fb4a-915a-4b4d-b4a9-e50af7de8741"
    },
    {
        id: "Pc Nsx Desktop I3-12100 8gb Ram Ssd240gb Free Dos",
        productName: 'Pc Nsx Desktop I3-12100 8gb Ram Ssd240gb Free Dos',
        category:'Pc',
        img: "https://http2.mlstatic.com/D_NQ_NP_988615-MLA51837058202_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1186069352-pc-nsx-desktop-i3-12100-8gb-ram-ssd240gb-free-dos-_JM#position=19&search_layout=stack&type=item&tracking_id=7a66fb4a-915a-4b4d-b4a9-e50af7de8741"
    },
    {
        id: "Pc Nsx Desktop I5-10400 Ssd240gb Freedos",
        productName: 'Pc Nsx Desktop I5-10400 Ssd240gb Freedos',
        category:'Pc',
        img: "https://http2.mlstatic.com/D_NQ_NP_921221-MLA51837378453_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1186095154-pc-nsx-desktop-i5-10400-ssd240gb-freedos-_JM#position=20&search_layout=stack&type=item&tracking_id=7a66fb4a-915a-4b4d-b4a9-e50af7de8741"
    },
    // ---------------------------------------------------------------------------------Notebooks-------------------------------------------------------------------------------
    {
        id:"EpsilonCloud",
        category:'Notebooks',
        productName: 'NSX EPSILON CLOUD',
        Model: "NSX EPSILON CLOUD",
        Descripcion: "La notebook EPSILON CLOUD fue diseñada para acompañarte todos los días. Posee una pantalla Full HD de 14” pulgadas que te permitirá disfrutar de una calidad de color e imagen. Su procesador Intel® Celeron™ ofrecen el máximo rendimiento con el menor consumo de energía. A su vez permite incorporar un SSD para aumentar su velocidad y capacidad de almacenamiento." ,
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/NSX-ARGENTINA/notebooks/cloud2.png',
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME",
        Camera: "0.3MP VGA",    
        ProcessorBrand : "Intel® Celeron™",
        Processor : "N3350 Dual Core 2.4GHz" ,
        Supply: "5000MAH 7.4V",
        Screen: '14" Full HD LCD',
        Dimensiones: "328 x 217 x 17 mm",
        Peso:"1.5kg",
        Teclado:"Español",
        Ram: "4GB LP-DDR4",
        Warranty: "1 año",
        StorageMain:"64GB + Slot libre para SSD",
        Usb:"3.0 x1, 2.0 x2",
        MiniHdmi: "x1",
        MicroSdCard: "x1",
        Rj45: "x1",
        Audio:"x1 (3.5mm) Mic Incorporado",
        Conectividad: "WIFI 802.11 B/G/N, BT4.0",
        url: "https://www.nsx.com.ar/MLA-1181831476-notebook-nsx-epsilon-cloud-4gb-ram-64ssd-freedos-_JM#position=5&search_layout=stack&type=item&tracking_id=ae082ac0-b62b-4d16-964a-02273f396029"
    },
    {
        id:"argusi3",
        productName:'NSX ARGUS i3', 
        category:'Notebooks',
        Model: "NSX ARGUS i3",
        Descripcion: "La tecnología que necesitas para trabajar desde tu casa y también en la oficina. Con la Notebook ARGUS I3 podrás potenciar tu rendimiento al máximo. Este producto tiene un procesador Intel® Core I3 y 4 GB de Memoria RAM que permite que puedas trabajar en movimiento, sin perder rendimiento, además cuenta con un SSD que abre archivos pesados a gran velocidad." ,
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/NSX-ARGENTINA/notebooks/argusi3.png',
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME",
        Camera: "2MP",    
        ProcessorBrand : "Intel®",
        Processor : "Core i3-10110U Dual Core 2.10 GHz Turbo 4.10 GHz" ,
        Supply: "4500MAH 11.4V",
        Screen: '15.6" FHD LCD',
        Dimensiones: "375 x 240 x 21 mm",
        Peso:"1.5kg",
        Teclado:"Español",
        Ram: "4GB + Slot disponible",
        Warranty: "1 año",
        StorageMain:"SSD 480GB SATA 2.5'",
        Usb:"3.0 x2 / Tipo C x1",
        MiniHdmi: "x1",
        MicroSdCard: "x1",
        Rj45: "x1",
        Audio:"x1 (3.5mm) Mic Incorporado",
        Conectividad: "WIFI 802.11 B/G/N, BT4.0",
        url: "https://www.nsx.com.ar/MLA-1184380636-notebook-nsx-argus-i3-10110u-4gb-480ssd-156-fhd-windows-11-_JM#position=4&search_layout=stack&type=item&tracking_id=3d01969b-335e-44b1-a267-e08ba77d659f"
    },
    {
        id:"argusi5",
        productName: 'NSX ARGUS i5',
        category:'Notebooks',
        Model: "NSX ARGUS i5",
        Descripcion: "La tecnología que necesitas para trabajar desde tu casa y también en la oficina. Con la Notebook ARGUS I3 podrás potenciar tu rendimiento al máximo. Este producto tiene un procesador Intel® Core I3 y 4 GB de Memoria RAM que permite que puedas trabajar en movimiento, sin perder rendimiento, además cuenta con un SSD que abre archivos pesados a gran velocidad." ,
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/NSX-ARGENTINA/notebooks/argusi5.png',
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME",
        Camera: "2MP",    
        ProcessorBrand : "Intel®",
        Processor : "Core i5-10210U Quad Core 1.60GHz Turbo 4.20GHz" ,
        Supply: "4500MAH 11.4V",
        Screen: '15.6" FHD LCD',
        Dimensiones: "375 x 240 x 21 mm",
        Peso:"1.5kg",
        Teclado:"Español",
        Ram: "8GB + Slot disponible",
        Warranty: "1 año",
        StorageMain:"SSD 480GB M2 NVME",
        Usb:"3.0 x2 / Tipo C x1",
        MiniHdmi: "x1",
        MicroSdCard: "x1",
        Rj45: "x1",
        Audio:"x1 (3.5mm) Mic Incorporado",
        Conectividad: "WIFI 802.11 B/G/N, BT4.0",
        url: "https://www.nsx.com.ar/MLA-1184440056-notebook-nsx-argus-i5-10210u-8gb-ram-480-ssd-free-dos-_JM#position=2&search_layout=stack&type=item&tracking_id=5d9aa4ed-2c07-4512-a592-01f13adf6e04"
    },
    {
        id:"alkoni5",
        productName: 'NSX ALKON i5',
        category:'Notebooks',
        Model: "NSX ALKON i5",
        Descripcion: "Redefine tu forma de trabajar. Con la Notebook ALKON I5 podrás potenciar tu rendimiento al máximo. Este portatil tiene un procesador Intel® Core I5 y 8 GB de Memoria RAM más que suficiente para llegar a la efectividad que necesitas, además cuenta con un SSD que permite aumentar tu capacdad de almacenamiento y tu velocidad de carga." ,
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/NSX-ARGENTINA/notebooks/alkoni5.png',
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME",
        Camera: "1MP",    
        ProcessorBrand : "Intel®",
        Processor : "Core i5-1035G1 Caché de 6M Turbo 3,60GHz" ,
        Supply: "4000MAH 11.4V",
        Screen: '14" FULL HD IPS',
        Dimensiones: "375 x 240 x 21 mm",
        Peso:"1.5kg",
        Teclado:"Español",
        Ram: "8GB DDR4-2666 + Slot disponible hasta 32GB",
        Warranty: "1 año",
        StorageMain:"SSD 240GB SATA 2.5'",
        Usb:"3.0 x2 / Tipo C x1",
        MiniHdmi: "x1",
        MicroSdCard: "x1",
        Rj45: "x1",
        Audio:"x1 (3.5mm) Mic Incorporado",
        Conectividad: "WIFI 802.11 B/G/N 2.4GHZ + 5.0 GHZ / BT4.0",
        url: "https://www.nsx.com.ar/MLA-1181833848-notebook-nsx-alkon-14-fhd-i5-1035g1-8gb-ssd-240-w11home-_JM#position=3&search_layout=stack&type=item&tracking_id=bf81cf99-9e00-45ec-ab5b-726ebd321e0c"
    },
    {
        id:"alkoni7",
        productName:'NSX ALKON i7',
        category:'Notebooks',
        Model: "NSX ALKON i7",
        Descripcion: "Redefine tu forma de trabajar. Con la Notebook ALKON I7 podrás potenciar tu rendimiento al máximo. Este portatil tiene un procesador Intel® Core I7 y 8 GB de Memoria RAM más que suficiente para llegar a la efectividad que necesitas, además cuenta con un SSD que permite aumentar tu capacdad de almacenamiento y tu velocidad de carga." ,
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/NSX-ARGENTINA/notebooks/alkoni7.png',
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME",
        Camera: "1MP",    
        ProcessorBrand : "Intel®",
        Processor : "Core i7-1065G1 Caché de 6M Turbo 3,60GHz" ,
        Supply: "4000MAH 11.4V",
        Screen: '14" FULL HD IPS',
        Dimensiones: "375 x 240 x 21 mm",
        Peso:"1.5kg",
        Teclado:"Español",
        Ram: "8GB DDR4-2666 + Slot disponible hasta 32GB",
        Warranty: "1 año",
        StorageMain:"SSD 240GB SATA 2.5'",
        Usb:"3.0 x2 / Tipo C x1",
        MiniHdmi: "x1",
        MicroSdCard: "x1",
        Rj45: "x1",
        Audio:"x1 (3.5mm) Mic Incorporado",
        Conectividad: "WIFI 802.11 B/G/N 2.4GHZ + 5.0 GHZ / BT4.0",
        url:"https://www.nsx.com.ar/MLA-1184053713-notebook-nsx-alkon-i7-1065g1-8gb-240-ssd-14-windows-11-home-_JM#position=2&search_layout=stack&type=item&tracking_id=bf9a591f-3d8a-4581-a518-f47467a5fae6"
    },
    {
        id:"epsilon",
        productName:'NSX EPSILON',
        category:'Notebooks',
        Model: "NSX EPSILON",
        Descripcion: "La notebook EPSILON cuenta con un diseño compacto y funcional. Posee una pantalla Full HD de 14” pulgadas que te permitirá disfrutar de una calidad de color e imagen. Su procesador Intel® Dual Core junto con su GPU ofrecen el máximo rendimiento con el menor consumo de energía. A su vez que permite ejecutar cualquier tarea con facilidad." ,
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/NSX-ARGENTINA/notebooks/epsilon.png',
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME",
        Camera: "0.3MP VGA",    
        ProcessorBrand : "Intel®",
        Processor : "Celeron N3350 2.4 GHz" ,
        Supply: "4500MAH 7.6V",
        Screen: '14.1" FULL HD LED',
        Dimensiones: "330 x 221 x 21 mm",
        Peso:"1.5kg",
        Teclado:"Español",
        Ram: "4GB LP-DDR3",
        Warranty: "1 año",
        StorageMain:"SSD 64GB SATA 2.5'",
        Usb:"3.0 x2 / Tipo C x1",
        MiniHdmi: "x1",
        MicroSdCard: "x1",
        Rj45: "x1",
        Audio:"x1 (3.5mm) Mic Incorporado",
        Conectividad: "WIFI 802 11N BT4.0",
        url: "https://www.nsx.com.ar/notebook-nsx-epsilon-nccf5s64w-negra-141-intel-celeron-n3350-4gb-de-ram-64gb-ssd-intel-hd-graphics-1920x1080px-windows-11-home/p/MLA19686857?pdp_filters=seller_id:293425181#position=1&search_layout=stack&type=item&tracking_id=b17c7f51-327b-4b7b-bd61-0c881446f4be"
    },
    // {
    //     id:"omicron",
    //     Model: "NSX OMICRON",
    //     Descripcion: "La tecnología que necesitas para trabajar desde tu casa y también en la oficina. Con la Notebook OMICRON podrás potenciar tu rendimiento al máximo. Este producto tiene un procesador Intel® Core I3 y 12 GB de Memoria RAM que permite que puedas trabajar en movimiento, sin perder rendimiento, además abre archivos pesados a gran velocidad." ,
    //     img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/NSX-ARGENTINA/notebooks/omicron.png',
    //     OperatingSystem:"Windows",
    //     OperatingSystemVersion:"10 PRO",
    //     Camera: "0.3MP VGA",    
    //     ProcessorBrand : "Intel®",
    //     Processor : "Core i3-1005G1" ,
    //     Supply: "4500MAH 7.6V",
    //     Screen: '14" FULL HD LCD',
    //     Dimensiones: "320 x 270 x 18 mm",
    //     Peso:"1.5kg",
    //     Teclado:"Español",
    //     Ram: "12gb",
    //     Warranty: "1 año",
    //     StorageMain:"SSD 480GB SATA 2.5'",
    //     Usb:"x2",
    //     MiniHdmi: "x1",
    //     MicroSdCard: "x1",
    //     Rj45: "no",
    //     Audio:"x1 (3.5mm) Mic Incorporado",
    //     Conectividad: "AC WIFI / BT4.0",
    // },
    {
        id:"sigma",
        productName:'NSX SIGMA',
        category:'Notebooks',
        Model: "NSX SIGMA",
        Descripcion: "Con la Notebook SIGMA podrás potenciar tu rendimiento al máximo. Gracias a los procesadores Intel® Core i5 de Décima Generación podrás aumentar la rapidez del equipo en las tareas cotidianas, realizando tus tareas más exigentes y trabajando en movimiento, sin perder rendimiento. Podrás abrir tus aplicaciones, archivos de gran tamaño y cargar los niveles de tus juegos favoritos a gran velocidad." ,
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/NSX-ARGENTINA/notebooks/sigma.png',
        OperatingSystem:"Windows",
        OperatingSystemVersion:"10 PRO",
        Camera: "0.3MP VGA",    
        ProcessorBrand : "Intel®",
        Processor : "Core i5-1035G1" ,
        Supply: "4500MAH 7.6V",
        Screen: '14" FULL HD LCD',
        Dimensiones: "320 x 270 x 18 mm",
        Peso:"1.5kg",
        Teclado:"Español",
        Ram: "12gb",
        Warranty: "1 año",
        StorageMain:"SSD 480GB SATA 2.5'",
        Usb:"x2",
        MiniHdmi: "x1",
        MicroSdCard: "x1",
        Rj45: "no",
        Audio:"x1 (3.5mm) Mic Incorporado",
        Conectividad: "AC WIFI / BT4.0",
        url: "https://www.nsx.com.ar/MLA-1184439805-notebook-nsx-sigma-i5-1035g4-12gb-ram-480-ssd-windows-10-pro-_JM#position=5&search_layout=stack&type=item&tracking_id=9db929c3-d08e-4002-93af-b69f1f79bb30"
    },
    {
        id:"omega",
        productName:'NSX OMEGA',
        category:'Notebooks',
        Model: "NSX OMEGA",
        Descripcion: "Con la notebook OMEGA no has de sacrificar rendimiento para tener un equipo pequeño: está diseñado para que hagas lo que quieras en el mínimo tiempo posible y sin complicarte la vida. Los procesadores Intel® Core I7 ofrecen una capacidad de respuesta superior, mientras que asegura el rendimiento necesario para acometer tareas exigentes de forma eficiente." ,
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/NSX-ARGENTINA/notebooks/omega.png',
        OperatingSystem:"Windows",
        OperatingSystemVersion:"10 PRO",
        Camera: "0.3MP VGA",    
        ProcessorBrand : "Intel®",
        Processor : "Core i5-1035G1" ,
        Supply: "5000MAH 7.6V",
        Screen: '14" FULL HD LCD',
        Dimensiones: "320 x 270 x 18 mm",
        Peso:"1.5kg",
        Teclado:"Español",
        Ram: "12gb",
        Warranty: "1 año",
        StorageMain:"SSD 500GB SATA 2.5'",
        Usb:"x2",
        MiniHdmi: "x1",
        MicroSdCard: "x1",
        Rj45: "no",
        Audio:"x1 (3.5mm) Mic Incorporado",
        Conectividad: "AC WIFI / BT4.0",
        url: "https://www.nsx.com.ar/MLA-1184446724-notebook-nsx-omega-i7-12gb-500ssd-windows-10-pro-_JM#position=5&search_layout=stack&type=item&tracking_id=f91cae35-68f1-45ee-a569-56aee119bb1b"
    },
    {
        id:"Kairosi5",
        productName:'NSX KAIROS I5 ULTRASLIM',
        category:'Notebooks',
        Model: "NSX KAIROS I5 ULTRASLIM",
        Descripcion: "La notebook KAIRÓS i5 cuenta con un diseño compacto y funcional. Posee los nuevos procesadores Intel® CoreTM i5 de 10a generación, que junto a SSD puedes experimentar una mejora monumental en las velocidades de lectura / escritura, ideal para tareas que requieren multiples procesos. Con un chasis sólido y liviano, su acabado metálico de primera calidad llama la atención y se destaca entre la multitud." ,
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/NSX-ARGENTINA/notebooks/kairosi5ultraslim.png',
        OperatingSystem:"Windows",
        OperatingSystemVersion:"10 PRO",
        Camera: "0.3MP VGA",    
        ProcessorBrand : "Intel®",
        Processor : "Core i5-1021OU" ,
        Supply: "3600MAH 11.44V",
        Screen: '14" FULL HD IPS',
        Dimensiones: "320 x 270 x 18 mm",
        Peso:"1.5kg",
        Teclado:"Español",
        Ram: "16gb",
        Warranty: "1 año",
        StorageMain:"SSD 250 hasta 1TB",
        Usb:"x2",
        MiniHdmi: "no",
        MicroSdCard: "no",
        Rj45: "no",
        Audio:"x1 (3.5mm) Mic Incorporado",
        Conectividad: "AC WIFI / BT4.0",
        url: "https://www.nsx.com.ar/MLA-1184440635-notebook-nsx-kairos-i5-10210u-16gb-250-ssd-14-windows-10-pro-_JM#position=5&search_layout=stack&type=item&tracking_id=2fe2d17e-9e7f-42ec-84d4-4ac3c3d29b5d"
    },
    {
        id:"Kairosi7",
        productName:'NSX KAIROS I7 ULTRASLIM',
        category:'Notebooks',
        Model: "NSX KAIROS I7 ULTRASLIM",
        Descripcion: "Un portátil que incorporan los procesadores Intel® CoreTM i7 de 10a generación, los más modernos, hasta con 16 GB de RAM, lo que resulta ideal para todas las tareas informáticas y de entretenimiento diarias. Con respecto al almacenamiento, tiene un disco de estado sólido (SSD) para obtener velocidades de acceso a los datos más rápidas y mejorar enormemente los tiempos de arranque, además de la protección de los datos" ,
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/NSX-ARGENTINA/notebooks/kairosi7ultraslim.png',
        OperatingSystem:"FREEDOS",
        OperatingSystemVersion:"FreeDOS",
        Camera: "0.3MP VGA",    
        ProcessorBrand : "Intel®",
        Processor : "Core i7-10510U" ,
        Supply: "3600MAH 11.44V",
        Screen: '14" FULL HD IPS',
        Dimensiones: "320 x 270 x 18 mm",
        Peso:"1.5kg",
        Teclado:"Español",
        Ram: "16gb",
        Warranty: "1 año",
        StorageMain:"SSD 500 hasta 1TB",
        Usb:"x2",
        MiniHdmi: "no",
        MicroSdCard: "no",
        Rj45: "no",
        Audio:"x1 (3.5mm) Mic Incorporado",
        Conectividad: "AC WIFI / BT4.0",
        url: "https://www.nsx.com.ar/MLA-1184408389-notebook-nsx-kairos-i7-105100u-16gb-500-ssd-14-freedos-_JM#position=1&search_layout=stack&type=item&tracking_id=512b2f07-128e-4799-8029-bb0ba719f588"
    },

    // -------------------------------------------------------Accesorios----------------------------------------------------------------------------
    {
        category: "Monitor",
        productName:'Monitor Gamer Samsung F24t35 Led 24  Azul Y Gris 100v/240v',
        id: "Monitor Gamer Samsung F24t35 Led 24  Azul Y Gris 100v/240v",
        img: "https://http2.mlstatic.com/D_NQ_NP_918060-MLA52028333672_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1201726748-monitor-gamer-samsung-f24t35-led-24-azul-y-gris-100v240v-_JM"
    },
    {
        category: "Monitor",
        productName:'Monitor Curvo 34 Samsung A650 Ultrawide Wqhd Hd 100hz 5ms',
        id: "Monitor Curvo 34 Samsung A650 Ultrawide Wqhd Hd 100hz 5ms ",
        img: "https://http2.mlstatic.com/D_NQ_NP_907083-MLA52029626772_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1202206124-monitor-curvo-34-samsung-a650-ultrawide-wqhd-hd-100hz-5ms-_JM"
    },
    {
        category: "Monitor",
        productName:'Monitor Samsung Odyssey Crg5 24',
        id: "Monitor Samsung Odyssey Crg5 24'' Curvo oferta!!! ",
        img: "https://http2.mlstatic.com/D_NQ_NP_851538-MLA52028187083_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1201726442-monitor-samsung-odyssey-crg5-24-curvo-_JM"
    },
    {
        category: "Silla",
        productName:'Silla Gamer Corsair T3 Rush Grey/white',
        id: "Silla Gamer Corsair T3 Rush Grey/white",
        img: "https://http2.mlstatic.com/D_NQ_NP_786366-MLA52028766521_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1202204815-silla-gamer-corsair-t3-rush-greywhite-_JM"
    },
    {
        category: "Silla",
        productName:'Silla Gamer Corsair T2 Road Negra Y Blanco (cf-9010007-w)',
        id: "Silla Gamer Corsair T2 Road Negra Y Blanco (cf-9010007-w)",
        img: "https://http2.mlstatic.com/D_NQ_NP_724021-MLA52027646111_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1202321030-silla-gamer-corsair-t2-road-negra-y-blanco-cf-9010007-w-_JM"
    },
    {
        category: "Silla",
        productName:'Silla Gamer Msi Mag Series Ch130x',
        id: "Silla Gamer Msi Mag Series Ch130x ",
        img: "https://http2.mlstatic.com/D_NQ_NP_978154-MLA52028741693_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1201900413-silla-gamer-msi-mag-series-ch130x-_JM"
    },
    {
        category: "Mouse",
        productName:'Mouse Patriot Gamer V551 Rgb Optical Usb',
        id: "Mouse Patriot Gamer V551 Rgb Optical Usb",
        img: "https://http2.mlstatic.com/D_NQ_NP_675498-MLA47516617995_092021-O.webp",
        url: "https://www.nsx.com.ar/MLA-1103532528-mouse-patriot-gamer-v551-rgb-optical-usb-_JM#position=1&search_layout=stack&type=item&tracking_id=2c958cba-b8f3-4e4d-8edf-cab1a5924cb4"
    },
    {
        category: "Mouse",
        productName:'Mouse Patriot Vipper Gamig V550 Rgb Optical Usb',
        id: "Mouse Patriot Vipper Gamig V550 Rgb Optical Usb",
        img: "https://http2.mlstatic.com/D_NQ_NP_884067-MLA44093897825_112020-O.webp",
        url: "https://www.nsx.com.ar/MLA-897459381-mouse-patriot-vipper-gamig-v550-rgb-optical-usb-_JM#position=2&search_layout=stack&type=item&tracking_id=2c958cba-b8f3-4e4d-8edf-cab1a5924cb4"
    },
    {
        category: "Mouse",
        productName:'Silla Gamer Msi Mag Series Ch130x',
        id: "Silla Gamer Msi Mag Series Ch130x ",
        img: "https://http2.mlstatic.com/D_NQ_NP_774405-MLA51841116029_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1186225444-mouse-gamer-qbox-qbm962-cable-2-botones-by-nsx-_JM#position=3&search_layout=stack&type=item&tracking_id=2c958cba-b8f3-4e4d-8edf-cab1a5924cb4"
    },
    {
        category: "Teclado",
        productName:'Teclado Gamer Patriot Viper V765 Rgb Kailh White Switch Pro',
        id: "Teclado Gamer Patriot Viper V765 Rgb Kailh White Switch Pro",
        img: "https://http2.mlstatic.com/D_NQ_NP_616340-MLA44094304236_112020-O.webp",
        url: "https://www.nsx.com.ar/MLA-897467858-teclado-gamer-patriot-viper-v765-rgb-kailh-white-switch-pro-_JM#position=1&search_layout=stack&type=item&tracking_id=da68302d-c5db-4395-987e-49e180b20462"
    },
    {
        category: "Teclado",
        productName:'Teclado Gaming Viper V730 Led Red Mechanical Pv730mbulgm',
        id: "Teclado Gaming Viper V730 Led Red Mechanical Pv730mbulgm",
        img: "https://http2.mlstatic.com/D_NQ_NP_911035-MLA47517193363_092021-O.webp",
        url: "https://www.nsx.com.ar/MLA-1103489508-teclado-gaming-viper-v730-led-red-mechanical-pv730mbulgm-_JM#position=2&search_layout=stack&type=item&tracking_id=da68302d-c5db-4395-987e-49e180b20462"
    },
    {
        category: "Teclado",
        productName:'Teclado Gamer Qbox K9260 Rgb Rainbow Antighost Semi-mecanico',
        id: "Teclado Gamer Qbox K9260 Rgb Rainbow Antighost Semi-mecanico",
        img: "https://http2.mlstatic.com/D_NQ_NP_999122-MLA51905460890_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1190168699-teclado-gamer-qbox-k9260-rgb-rainbow-antighost-semi-mecanico-_JM#position=3&search_layout=stack&type=item&tracking_id=da68302d-c5db-4395-987e-49e180b20462"
    },
    {
        category: "Auricular",
        productName:'Auricular Qbox Oficina H004 Notebook Pc By Nsx',
        id: "Auricular Qbox Oficina H004 Notebook Pc By Nsx",
        img: "https://http2.mlstatic.com/D_NQ_NP_655007-MLA51840717524_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1186149090-auricular-qbox-oficina-h004-notebook-pc-by-nsx-_JM#position=3&search_layout=stack&type=item&tracking_id=4e9cf32a-082e-4983-bd5e-4a5a0040fd6b"
    },
    {
        category: "Auricular",
        productName:'Auricular Gamer Patriot Viper V330 Stereo Gaming Headset',
        id: "Auricular Gamer Patriot Viper V330 Stereo Gaming Headset",
        img: "https://http2.mlstatic.com/D_NQ_NP_680732-MLA47515555312_092021-O.webp",
        url: "https://www.nsx.com.ar/MLA-1103494461-auricular-gamer-patriot-viper-v330-stereo-gaming-headset-_JM#position=4&search_layout=stack&type=item&tracking_id=4e9cf32a-082e-4983-bd5e-4a5a0040fd6b"
    },
    {
        category: "Auricular",
        productName:'Auricular Gamer Patriot Viper V360 7.1 Con Sonido Envolvente',
        id: "Auricular Gamer Patriot Viper V360 7.1 Con Sonido Envolvente",
        img: "https://http2.mlstatic.com/D_NQ_NP_869815-MLA47516067484_092021-O.webp",
        url: "https://www.nsx.com.ar/MLA-1103494662-auricular-gamer-patriot-viper-v360-71-con-sonido-envolvente-_JM#position=6&search_layout=stack&type=item&tracking_id=4e9cf32a-082e-4983-bd5e-4a5a0040fd6b"
    },
    {
        category: "Disco",
        productName:'Disco Solido Patriot P210 Hd Ssd 1tb Sata3 2.5',
        id: "Disco Solido Patriot P210 Hd Ssd 1tb Sata3 2.5",
        img: "https://http2.mlstatic.com/D_NQ_NP_941366-MLA51863619640_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1132010407-disco-solido-patriot-p210-hd-ssd-1tb-sata3-25-_JM#position=1&search_layout=stack&type=item&tracking_id=e498e581-b11b-4f6f-8aff-740b7f08993b"
    },
    {
        category: "Disco",
        productName:'Disco Sólido Interno Ssd Patriot 256gb P210',
        id: "Disco Sólido Interno Ssd Patriot 256gb P210 ",
        img: "https://http2.mlstatic.com/D_NQ_NP_902360-MLA47512853629_092021-O.webp",
        url: "https://www.nsx.com.ar/MLA-1103413398-disco-solido-interno-ssd-patriot-256gb-p210-_JM#position=2&search_layout=stack&type=item&tracking_id=e498e581-b11b-4f6f-8aff-740b7f08993b"
    },
    {
        category: "Disco",
        productName:'Disco Ssd Estado Solido 128gb 2,5 Patriot P210',
        id: "Disco Ssd Estado Solido 128gb 2,5 Patriot P210",
        img: "https://http2.mlstatic.com/D_NQ_NP_618307-MLA49655014676_042022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1132015300-disco-ssd-estado-solido-128gb-25-patriot-p210-_JM#position=3&search_layout=stack&type=item&tracking_id=e498e581-b11b-4f6f-8aff-740b7f08993b"
    },
    // ------------------------------------------------ Stock----------------------------------------------------------------------------------------------
    {
        id:"PGI5VNS4W-01",
        productName:'Patriot P310',
        category: "Pc",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerComputers/PGI5VNS4W-01.jpg',
        Model: "Model SG60",
        sku:"PGI5VNS4W-01",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME PLUS",
        MotherBoardBrand:"MSI",
        MotherBoardModel:"B560M-A PRO WIFI",
        ProcessorBrand : "Intel",
        Processor : "Core i5 10400" ,
        StorageMain:"480GB",
        StorageBrand:"Patriot P310",
        StorageTechnology:"M.2 NVME",
        Ram: "Patriot Viper RGB 8GB x2",
        RamTechnology:"DDR4-3200",
        GraphicBrand:"EVGA",
        GraphicModel:"RTX 2060 SC",
        CaseBrand:"MSI",
        CaseModel:"100M",
        SupplyBrand:"EVGA",
        Supply:"600W",
        MouseBrand:"EVGA",
        MouseModel:"X17",
        KeyboardBrand:"EVGA",
        KeyboardModel:"Z12",
        CoolerBrand:"STOCK",
        Cooler:"STOCK",
    },
    {
        id:"PGI5VNS4W-02",
        productName:'Patriot P310',
        category: "PC_Gaming",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerComputers/PGI5VNS4W-02.jpg',
        Model: "Model SG70",
        sku:"PGI5VNS4W-02",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME PLUS",
        MotherBoardBrand:"MSI",
        MotherBoardModel:"B660 PRO WIFI",
        ProcessorBrand : "Intel",
        Processor : "Core i5 12400F" ,
        StorageMain:"480GB",
        StorageBrand:"Patriot P310",
        StorageTechnology:"M.2 NVME",
        Ram: "Patriot Viper RGB 8GB x2",
        RamTechnology:"DDR4-3200",
        GraphicBrand:"EVGA",
        GraphicModel:"RTX 3060 SC",
        CaseBrand:"MSI",
        CaseModel:"100M",
        SupplyBrand:"MSI",
        Supply:"650W",
        MouseBrand:"EVGA",
        MouseModel:"X17",
        KeyboardBrand:"EVGA",
        KeyboardModel:"Z12",
        CoolerBrand:"STOCK",
        Cooler:"STOCK",
    },
    {
        id:"PGR7VNS4W-01",
        category: "PC_Gaming",
        productName:'MAG X570 TOMAHAWK MAX WIFI',
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerComputers/PGR7VNS4W-01.jpg',
        Model: "Model SG85",
        sku:"PGR7VNS4W-01",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME ADVANCE",
        MotherBoardBrand:"MSI",
        MotherBoardModel:"MAG X570 TOMAHAWK MAX WIFI",
        ProcessorBrand : "AMD",
        Processor : "Ryzen 7 5700X" ,
        StorageMain:"1TB",
        StorageBrand:"Patriot P300",
        StorageTechnology:"M.2 NVME",
        Ram: "Patriot Viper RGB 8GB x2",
        RamTechnology:"DDR4-3600",
        GraphicBrand:"NVIDIA EVGA",
        GraphicModel:"RTX 3070 TI FTW3 ULTRA",
        CaseBrand:"MSI",
        CaseModel:"111R",
        SupplyBrand:"MSI",
        Supply:"650W",
        MouseBrand:"EVGA",
        MouseModel:"X17",
        KeyboardBrand:"EVGA",
        KeyboardModel:"Z12",
        CoolerBrand:"MSI",
        Cooler:"MAG CORE LIQUID P240",
    },
    {
        id:"PGI5VNS4W-03",
        productName:'Patriot P310',
        category: "PC_Gaming",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerComputers/PGI5VNS4W-03.jpg',
        Model: "Model SG60",
        sku:"PGI5VNS4W-03",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME PLUS",
        MotherBoardBrand:"MSI",
        MotherBoardModel:"B560M-A PRO WIFI",
        ProcessorBrand : "INTEL",
        Processor : "Core i5 10400" ,
        StorageMain:"480GB",
        StorageBrand:"Patriot P310",
        StorageTechnology:"M.2 NVME",
        Ram: "Patriot Viper RGB 16GB x2",
        RamTechnology:"DDR4-3200",
        GraphicBrand:"EVGA",
        GraphicModel:"RTX 2060 SC",
        CaseBrand:"MSI",
        CaseModel:"100M",
        SupplyBrand:"EVGA",
        Supply:"600W",
        MouseBrand:"EVGA",
        MouseModel:"X17",
        KeyboardBrand:"EVGA",
        KeyboardModel:"Z12",
        CoolerBrand:"STOCK",
        Cooler:"STOCK",
    },
    {
        id:"PGI5VNS4W-05",
        productName:'Patriot P310',
        category: "PC_Gaming",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerComputers/PGI5VNS4W-05.jpg',
        Model: "Model SG70",
        sku:"PGI5VNS4W-05",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME PLUS",
        MotherBoardBrand:"MSI",
        MotherBoardModel:"MSI B660 PRO WIFI",
        ProcessorBrand : "INTEL",
        Processor : "Core i5 12400F" ,
        StorageMain:"480GB",
        StorageBrand:"Patriot P310",
        StorageTechnology:"M.2 NVME",
        Ram: "Patriot Viper RGB 16GB x2",
        RamTechnology:"DDR4-3200",
        GraphicBrand:"EVGA",
        GraphicModel:"RTX 3060 SC",
        CaseBrand:"MSI",
        CaseModel:"100M",
        SupplyBrand:"MSI",
        Supply:"650W",
        MouseBrand:"EVGA",
        MouseModel:"X17",
        KeyboardBrand:"EVGA",
        KeyboardModel:"Z12",
        CoolerBrand:"STOCK",
        Cooler:"STOCK",
    },
    {
        id:"PGI5VNS4W-04",
        productName:'Patriot P300',
        category: "PC_Gaming",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerComputers/PGI5VNS4W-04.jpg',
        Model: "Model SG60",
        sku:"PGI5VNS4W-04",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME PLUS",
        MotherBoardBrand:"MSI",
        MotherBoardModel:"B560M-A PRO WIFI",
        ProcessorBrand : "INTEL",
        Processor : "Core i5 10400" ,
        StorageMain:"1TB",
        StorageBrand:"Patriot P300",
        StorageTechnology:"M.2 NVME",
        Ram: "Patriot Viper RGB 8GB x2",
        RamTechnology:"DDR4-3200",
        GraphicBrand:"EVGA",
        GraphicModel:"RTX 2060 SC",
        CaseBrand:"MSI",
        CaseModel:"100M",
        SupplyBrand:"EVGA",
        Supply:"600W",
        MouseBrand:"EVGA",
        MouseModel:"X17",
        KeyboardBrand:"EVGA",
        KeyboardModel:"Z12",
        CoolerBrand:"STOCK",
        Cooler:"STOCK",
    },
    {
        id:"PGI5VNS4W-06",
        productName:'Patriot P300',
        category: "PC_Gaming",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerComputers/PGI5VNS4W-06.jpg',
        Model: "Model SG70",
        sku:"PGI5VNS4W-06",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME PLUS",
        MotherBoardBrand:"MSI",
        MotherBoardModel:"B660 PRO WIFI",
        ProcessorBrand : "INTEL",
        Processor : "Core i5 12400F" ,
        StorageMain:"1TB",
        StorageBrand:"Patriot P300",
        StorageTechnology:"M.2 NVME",
        Ram: "Patriot Viper RGB 8GB x2",
        RamTechnology:"DDR4-3200",
        GraphicBrand:"EVGA",
        GraphicModel:"RTX 3060 SC",
        CaseBrand:"MSI",
        CaseModel:"100M",
        SupplyBrand:"MSI",
        Supply:"650W",
        MouseBrand:"EVGA",
        MouseModel:"X17",
        KeyboardBrand:"EVGA",
        KeyboardModel:"Z12",
        CoolerBrand:"STOCK",
        Cooler:"STOCK",
    },
    {
        id:"PGR7VNS4W-02",
        productName:'Patriot P300',
        category: "PC_Gaming",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerComputers/PGR7VNS4W-02.jpg',
        Model: "Model SG85",
        sku:"PGR7VNS4W-02",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME ADVANCE",
        MotherBoardBrand:"MSI",
        MotherBoardModel:"MAG X570 TOMAHAWK MAX WIFI",
        ProcessorBrand : "AMD",
        Processor : "Ryzen 7 5700X" ,
        StorageMain:"1TB",
        StorageBrand:"Patriot P300",
        StorageTechnology:"M.2 NVME",
        Ram: "Patriot Viper RGB 16GB x2",
        RamTechnology:"DDR4-3600",
        GraphicBrand:"EVGA",
        GraphicModel:"RTX 3070 TI FTW3 ULTRA",
        CaseBrand:"MSI",
        CaseModel:"111R",
        SupplyBrand:"MSI",
        Supply:"650W",
        MouseBrand:"EVGA",
        MouseModel:"X17",
        KeyboardBrand:"EVGA",
        KeyboardModel:"Z12",
        CoolerBrand:"MSI",
        Cooler:"MAG CORE LIQUID P240",
    },
    {
        id:"PGR7VNS4W-03",
        productName:'Patriot P300',
        category: "PC_Gaming",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerComputers/PGR7VNS4W-03.jpg',
        Model: "Model SG85",
        sku:"PGR7VNS4W-03",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME ADVANCE",
        MotherBoardBrand:"MSI",
        MotherBoardModel:"MAG X570 TOMAHAWK MAX WIFI",
        ProcessorBrand : "AMD",
        Processor : "Ryzen 7 5700X" ,
        StorageMain:"1TB",
        StorageBrand:"Patriot P300",
        StorageTechnology:"M.2 NVME",
        Ram: "Patriot Viper RGB 32GB x2",
        RamTechnology:"DDR4-3600",
        GraphicBrand:"MSI",
        GraphicModel:"RTX 3070 VENTUS 2X OC",
        CaseBrand:"MSI",
        CaseModel:"111R",
        SupplyBrand:"MSI",
        Supply:"650W",
        MouseBrand:"EVGA",
        MouseModel:"X17",
        KeyboardBrand:"EVGA",
        KeyboardModel:"Z12",
        CoolerBrand:"MSI",
        Cooler:"MAG CORE LIQUID P240",
    },
    {
        id:"PGR7VNS4W-04",
        productName:'Patriot P300', 
        category: "PC_Gaming",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerComputers/PGR7VNS4W-04.jpg',
        Model: "Model SG85",
        sku:"PGR7VNS4W-04",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME ADVANCE",
        MotherBoardBrand:"MSI",
        MotherBoardModel:"MAG X570 TOMAHAWK MAX WIFI",
        ProcessorBrand : "AMD",
        Processor : "Ryzen 7 5700X" ,
        StorageMain:"1TB",
        StorageBrand:"Patriot P300",
        StorageTechnology:"M.2 NVME",
        Ram: "Patriot Viper RGB 16GB x2",
        RamTechnology:"DDR4-3600",
        GraphicBrand:"MSI",
        GraphicModel:"RTX 3070 VENTUS 2X OC",
        CaseBrand:"MSI",
        CaseModel:"111R",
        SupplyBrand:"MSI",
        Supply:"650W",
        MouseBrand:"EVGA",
        MouseModel:"X17",
        KeyboardBrand:"EVGA",
        KeyboardModel:"Z12",
        CoolerBrand:"MSI",
        Cooler:"MAG CORE LIQUID P240",
    },
    {
        id:"PCR58GB250-1",
        productName:'Ryzen 5 4600G',
        category: "PC_DESKTOP",
        img: 'https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxGamerOffice/nsx_sc45_1.jpg',
        Model: "Model SC45",
        sku:"PCR58GB250-1",
        OperatingSystem:"Windows",
        OperatingSystemVersion:"11 HOME STD",
        MotherBoardBrand:"MSI",
        MotherBoardModel:"PRO A520M-A PRO",
        ProcessorBrand : "AMD",
        Processor : "Ryzen 5 4600G " ,
        StorageMain:"250GB",
        StorageBrand:"Patriot P210",
        StorageTechnology:"SATA",
        Ram: "Patriot 8GB",
        RamTechnology:"DDR4-3200",
        GraphicBrand:"-",
        GraphicModel:"Integrated Graphics",
        CaseBrand:"Rippa",
        CaseModel:"ATX 101105",
        SupplyBrand:"Rippa",
        Supply:"60W",
        MouseBrand:"Rippa",
        MouseModel:"-",
        KeyboardBrand:"Rippa",
        KeyboardModel:"-",
    },

    // ---------------------------------------------------------------- Stock Office ----------------------------------------------
    {
        id: "Pc Gamer Ags Amd Ryzen5 5600g 16gb Ram 480 Ssd Oferta!!",
        productName:'Pc Gamer Ags Amd Ryzen5 5600g 16gb Ram 480 Ssd Oferta!',
        category:'PC_Gaming',
        img: "https://http2.mlstatic.com/D_NQ_NP_834373-MLA51930649629_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1192459918-pc-gamer-ags-amd-ryzen5-5600g-16gb-ram-480-ssd-oferta-_JM#position=1&search_layout=stack&type=item&tracking_id=dc17e9c4-1ba2-44be-908f-b6d7cf2167e6"
    },
    {
        id: "Pc Gamer Nsx Max Ryzen 5 3600 16gb 500ssd Rx6600xt Oferta!!!",
        productName:'Pc Gamer Nsx Max Ryzen 5 3600 16gb 500ssd Rx6600xt Oferta!',
        category:'PC_Gaming',
        img: "https://http2.mlstatic.com/D_NQ_NP_920057-MLA51779571080_092022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1181858436-pc-gamer-nsx-max-ryzen-5-3600-16gb-500ssd-rx6600xt-_JM#position=2&search_layout=stack&type=item&tracking_id=aa29ad47-5e01-47d0-8372-b6ea19bd7be1"
    },
    {
        id: "Pc Gamer Ags R7 5700g 16 Ram 480ssd 12 Cuotas Oferta!!! ",
        productName:'Pc Gamer Ags R7 5700g 16 Ram 480ssd 12 Cuotas Oferta!!',
        category:'PC_Gaming',
        img: "https://http2.mlstatic.com/D_NQ_NP_856107-MLA52026697791_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1192635430-pc-gamer-ags-r7-5700g-16-ram-480ssd-12-cuotas-oferta-_JM#position=3&search_layout=stack&type=item&tracking_id=a9747992-4790-474a-a308-9af6b48f4518"
    },
    {
        id: "Pc Gamer Ags R5 5600g 16gb 480ssd Hasta 12 Cuotas Oferta!!",
        productName:'Pc Gamer Ags R5 5600g 16gb 480ssd Hasta 12 Cuotas Oferta!',
        category:'PC_Gaming',
        img: "https://http2.mlstatic.com/D_NQ_NP_834373-MLA51930649629_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1192460047-pc-gamer-ags-r5-5600g-16gb-480ssd-hasta-12-cuotas-oferta-_JM#position=4&search_layout=stack&type=item&tracking_id=42c7f412-ebb3-4371-9e02-da2559a8b7b1"
    },
    {
        id: "Pc Nsx Asus Edition I5 10400f 16gb 500gb Ssd Gtx 1660 Ti Evo",
        productName:'Pc Nsx Asus Edition I5 10400f 16gb 500gb Ssd Gtx 1660 Ti Evo',
        category:'PC_Gaming',
        img: "https://http2.mlstatic.com/D_NQ_NP_995703-MLA51779054706_092022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1181845039-pc-nsx-asus-edition-i5-10400f-16gb-500gb-ssd-gtx-1660-ti-evo-_JM#position=5&search_layout=stack&type=item&tracking_id=08d50fa4-f480-4650-8600-ebf16cc352c9"
    },
    {
        id: "Pc Nsx Gaming Pro R5 5600g 16gb 500 Ssd Sata 2.5",
        productName:'Pc Nsx Gaming Pro R5 5600g 16gb 500 Ssd Sata 2.5',
        category:'PC_Gaming',
        img: "https://http2.mlstatic.com/D_NQ_NP_747273-MLA51777146542_092022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1181192951-pc-nsx-gaming-pro-r5-5600g-16gb-500-ssd-sata-25-_JM#position=6&search_layout=stack&type=item&tracking_id=79c87251-3a22-49a7-8ee9-24ad44afdcea"
    },
    {
        id: "Pc Gamer Ags Amd Ryzen 7 5700g 16gb Ram 480ssd Oferta!!!",
        productName:'Pc Gamer Ags Amd Ryzen 7 5700g 16gb Ram 480ssd Oferta!!',
        category:'PC_Gaming',
        img: "https://http2.mlstatic.com/D_NQ_NP_985866-MLA52026865030_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1192699715-pc-gamer-ags-amd-ryzen-7-5700g-16gb-ram-480ssd-oferta-_JM#position=7&search_layout=stack&type=item&tracking_id=0e3ad4bb-d2e0-4f03-93f8-9a74c0bf296a"
    },
    {
        id: "Pc Gamer Ags R5 5600g 16gb Ram 480ssd 24 Cuotas Oferta!!",
        productName:'Pc Gamer Ags R5 5600g 16gb Ram 480ssd 24 Cuotas Oferta!!', 
        category:'PC_Gaming',
        img: "https://http2.mlstatic.com/D_NQ_NP_881456-MLA52006121860_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1199794401-pc-gamer-ags-r5-5600g-16gb-ram-480ssd-24-cuotas-oferta-_JM#position=8&search_layout=stack&type=item&tracking_id=1181c15c-5abc-46f1-b0c9-dd22e82fbca4"
    },
    {
        id: "Pc Nsx Asus Edition I3 10100f 16gb 500gb Gtx 1650 Phoenix Oferta!!",
        productName:'Pc Nsx Asus Edition I3 10100f 16gb 500gb Gtx 1650 Phoenix Oferta!',
        category:'PC_Gaming',
        img: "https://http2.mlstatic.com/D_NQ_NP_718177-MLA51778971852_092022-O.webp",
        category:'PC_Gaming',
        url: "https://www.nsx.com.ar/MLA-1181890372-pc-nsx-asus-edition-i3-10100f-16gb-500gb-gtx-1650-phoenix-_JM#position=9&search_layout=stack&type=item&tracking_id=2389aea2-44c3-4d80-87ff-150fbdcd140f"
    },
    {
        id: "Pc Gamer Ags R7 5700g 16gb Ram 480ssd 24 Cuotas Oferta!!! ",
        productName:'Pc Gamer Ags R7 5700g 16gb Ram 480ssd 24 Cuotas Oferta!',
        category:'PC_Gaming',
        img: "https://http2.mlstatic.com/D_NQ_NP_628254-MLA52026680893_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1198724699-pc-gamer-ags-r7-5700g-16gb-ram-480ssd-24-cuotas-oferta-_JM#position=10&search_layout=stack&type=item&tracking_id=58e3d692-a9e1-4c6d-9045-5889f9dd87ff"
    },
    {
        id: "Pc Nsx Gaming Pro 4700s 16gb Gddr6 512ssd Sata 2.5 Rx550 2gb",
        productName:'Pc Nsx Gaming Pro 4700s 16gb Gddr6 512ssd Sata 2.5 Rx550 2gb',
        category:'PC_Gaming',
        img: "https://http2.mlstatic.com/D_NQ_NP_628254-MLA52026680893_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1181846203-pc-nsx-gaming-pro-4700s-16gb-gddr6-512ssd-sata-25-rx550-2gb-_JM#position=11&search_layout=stack&type=item&tracking_id=58e3d692-a9e1-4c6d-9045-5889f9dd87ff"
    },
    {
        id: "Pc Nsx Gaming Max I5 11400f 16gb Ram 500ssd Rx 6600 Oferta!!! ",
        productName:'Pc Nsx Gaming Max I5 11400f 16gb Ram 500ssd Rx 6600 Oferta!!',
        category:'PC_Gaming',
        img: "https://http2.mlstatic.com/D_NQ_NP_709086-MLA51779314082_092022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1181832310-pc-nsx-gaming-max-i5-11400f-16gb-ram-500ssd-rx-6600-_JM#position=14&search_layout=stack&type=item&tracking_id=58e3d692-a9e1-4c6d-9045-5889f9dd87ff"
    },
    {
        id: "Pc Gamer Nsx Amd R7 5800x 32gb Ram 1tb Ssd Rtx 3080 Ti Rog",
        productName:'Pc Gamer Nsx Amd R7 5800x 32gb Ram 1tb Ssd Rtx 3080 Ti Rog', 
        category:'PC_Gaming',
        img: "https://http2.mlstatic.com/D_NQ_NP_951795-MLA52049966071_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1199940410-pc-gamer-nsx-amd-r7-5800x-32gb-ram-1tb-ssd-rtx-3080-ti-rog-_JM#position=16&search_layout=stack&type=item&tracking_id=58e3d692-a9e1-4c6d-9045-5889f9dd87ff"
    },
    {
        id: "Pc Gamer Nsx By Corsair R7 5700g 16gb 1tb Rtx 3080 Ti Rog ",
        productName:'Pc Gamer Nsx By Corsair R7 5700g 16gb 1tb Rtx 3080 Ti Rog',
        category:'PC_Gaming',
        img: "https://http2.mlstatic.com/D_NQ_NP_948996-MLA52049764765_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1181872084-pc-nsx-desktop-4700s-16gb-ssd256gb-rx550-2gb-_JM#position=17&search_layout=stack&type=item&tracking_id=58e3d692-a9e1-4c6d-9045-5889f9dd87ff"
    },
    {
        id: "Pc Gamer Nsx By Cooler Master I5 12400f 16 1tb Rtx 3060 Ti V",
        productName:'Pc Gamer Nsx By Cooler Master I5 12400f 16 1tb Rtx 3060 Ti V',
        category:'PC_Gaming',
        img: "https://http2.mlstatic.com/D_NQ_NP_635846-MLA52049787208_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1199790869-pc-gamer-nsx-by-cooler-master-i5-12400f-16-1tb-rtx-3060-ti-v-_JM#position=20&search_layout=stack&type=item&tracking_id=58e3d692-a9e1-4c6d-9045-5889f9dd87ff"
    },
    {
        id: "Pc Gamer Nsx By Corsair R5 5600x 16gb 1tb Rtx 3080 Ti Rog",
        productName:'Pc Gamer Nsx By Corsair R5 5600x 16gb 1tb Rtx 3080 Ti Rog',
        category:'PC_Gaming',
        img: "https://http2.mlstatic.com/D_NQ_NP_853952-MLA52049911084_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1200252133-pc-gamer-nsx-by-corsair-r5-5600x-16gb-1tb-rtx-3080-ti-rog-_JM#position=21&search_layout=stack&type=item&tracking_id=58e3d692-a9e1-4c6d-9045-5889f9dd87ff"
    },
    {
        id: "Pc Gamer Nsx By Cooler Master I5 12400f 16 1tb Rtx 3060 Ti V",
        productName:'Pc Gamer Nsx By Cooler Master I5 12400f 16 1tb Rtx 3060 Ti V',
        category:'PC_Gaming',
        img: "https://http2.mlstatic.com/D_NQ_NP_887279-MLA52049211954_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1199130417-pc-gamer-nsx-by-cooler-master-i5-12400f-16-1tb-rtx-3060-ti-v-_JM#position=24&search_layout=stack&type=item&tracking_id=58e3d692-a9e1-4c6d-9045-5889f9dd87ff"
    },
    {
        id: "Pc Gamer Nsx By Corsair R7 5700g 16gb 1tb Rtx 3080 Ti Rog ",
        productName:'Pc Gamer Nsx By Corsair R7 5700g 16gb 1tb Rtx 3080 Ti Rog',
        category:'PC_Gaming',
        img: "https://http2.mlstatic.com/D_NQ_NP_948996-MLA52049764765_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1199805247-pc-gamer-nsx-by-corsair-r7-5700g-16gb-1tb-rtx-3080-ti-rog-_JM#position=18&search_layout=stack&type=item&tracking_id=58e3d692-a9e1-4c6d-9045-5889f9dd87ff"
    }
    
    
]

