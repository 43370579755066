import "./ConfiguradorColumnasStyle.css";

import React, { useContext } from 'react';

import { Link } from 'react-router-dom';
import { ListadoContext } from '../ContextConfigurador/ListadoContext';
import { PreconfiguradoContext } from '../ContextConfigurador/PreconfiguradoContext';
import Price from '../Price';
import { useParams } from 'react-router-dom';
import { MarcaProcesadorContext } from "../ContextConfigurador/MarcaProcesadorContext";

export function PreconfigColumnaDer({ minWidth }) {
	const imagenCategoria = process.env.PUBLIC_URL + "/imagenes/imagenes-configurador/";
	const { param } = useParams();
	const { listado, typeDetalle } = useContext(ListadoContext);
	const { counterRam, counterDisco } = useContext(PreconfiguradoContext);
	const { paso } = useContext(MarcaProcesadorContext);

	let aComponentesAgregados = [];
	typeDetalle.forEach(element => {
		if (listado[element].length !== 0) {
			aComponentesAgregados.push(listado[element]);
		}
	});
	let listadoAgregados = []
	aComponentesAgregados.forEach(element => {
		element.forEach(item => {
			listadoAgregados.push(item);
		})
	});

	const pedidoCompleto =
		listado.microprocesador.length > 0 &&
		listado.motherboard.length > 0 &&
		listado.ram.length > 0 &&
		listado.disco.length > 0 &&
		listado.gabinete.length > 0 &&
		listado.video.length > 0 &&
		listado.fuente.length > 0 &&
		listado.cooler.length > 0;


	//--------------------------------------- Array de objetos agregados CARRITO para validar el próximo componente.-------------------

	let carrito = []
	aComponentesAgregados.forEach(element => {
		element.forEach(item => {
			carrito.push(item);
		})
	});

	// console.log(carrito);



	function RefreshButton() {
		window.location.reload();
	}


	//----------------------------------------------------------------------------------------------------------------------

	return (
		<>
			<section className="armaTuPc mt-5 text-center" id="armarTuPc__columnaDer">
				<div className="m-3">
					<h4 className='confEleg'>Configuracion elegida</h4>
					<ul className="pl-0  mt-3" id="listado-compra">
						{/*
						{listadoAgregados.length > 6 ?
							<li id="compra_" style={{ display: 'flex', justifyContent: 'space-between' }}>
								<>
									<p className="acomodoAltura">
										Servicio de armado
									</p>
									<p className='preciosDerecha'>
										{`$${(Number(2500))},00`}
									</p>
								</>
							</li>
							: null} */}
						{listadoAgregados.length > 0 && listadoAgregados.map((elemento) => (
							<li key={"preconfig_" + elemento.Alias}
								id={`compra_${elemento.Alias}`}
								style={{ display: 'flex', justifyContent: 'space-between' }}>
								<p className="acomodoAltura">
									{elemento.Tipo === 'ram' && <>{counterRam} x</>}
									{elemento.Tipo === 'disco' && <>{counterDisco} x</>}  {
										elemento.Precio > 0 && elemento.Descripcion
									}
								</p>
								<p className='preciosDerecha'>
									{elemento.Moneda}
									{elemento.Precio > 0 && `ARS $  ${(new Intl.NumberFormat().format(elemento.Precio))}`}
								</p>
							</li>
						))}
					</ul>
					<hr />
					<Price />

					<div className={`d-grid gap-2 mt-2 `}>
						<span className="d-inline-block tooltip-compra d-grid gap-2 mt-2" tabIndex="0" data-bs-placement="bottom" data-bs-toggle="tooltip"
							title="Se debe seleccionar mínimo: procesador, mother, ram, disco, fuente y gabinete">
							<Link to={`/Order/${param}`}
								type="button"
								className={`btn btn-primary botonFinalizarPedido ${!pedidoCompleto > 0 && 'disabledCategory'}`}
								id="comprar">
								Finalizar Pedido
							</Link>

							<button onClick={RefreshButton} className="btn btn-outline-danger botonReiniciarConfiguracion">
								Reiniciar configuracion
							</button>
						</span>
					</div>
				</div>
			</section>
		</>
	)
};
