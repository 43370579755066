import React from 'react'
import { FaWhatsapp } from 'react-icons/fa';
import './ButtonWhatsapp.css'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ButtonWhatsapp = () => {
    const phoneNumber = '5491134413853';
    const message = 'Hola! Estoy configurando mi pc gamer NSX y quiero pedir más información';

    const openWhatsAppChat = () => {
        window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`, '_blank');
        // https://api.whatsapp.com/send?phone=5491134413853
    };

    const tooltip = (
        <Tooltip id="whatsapp-tooltip">Hola! Estoy configurando mi pc gamer NSX y quiero pedir más información.</Tooltip>
    );

    return (
        <OverlayTrigger placement="left" overlay={tooltip}>
            <div className="whatsapp-button" onClick={openWhatsAppChat}>
                <FaWhatsapp size={60} color="#fff" style={{ margin: '0px' }} />
            </div>
        </OverlayTrigger>
    )
}

export default ButtonWhatsapp