import React from 'react'
import { Button } from 'react-bootstrap'
import './FooterSNStyle.css'

export const FooterSN = () => {
  return (
    <div>
      <div className='footer_1'>
        {/* <img src={"https://raw.githubusercontent.com/RamiroSB/imagenes/main/nsxgamer/nsxGamingLogoFooter.png"} alt="Imagen NSX"></img> */}
        <img src={process.env.PUBLIC_URL + "/imagenes/imagenes-logo/LOGO-GAMING-1.png"} alt="Logo NSX" className='logoFooter mt-5'></img>
        <p style={{margin:"0px"}}>NSX ARGENTINA</p>
      </div>
    </div>
  )
}