import React, { useEffect, useState } from 'react'


const CompraPendiente = () => {


    return (
        <div>
            <div style={{ marginTop: '2em' }}>
                <h1 style={{ textAlign: 'center' }}>COMPRA EN ESTADO PENDIENTE!!!!</h1>
                <h2 style={{ textAlign: 'center' }}>Te avisaremos cuando tu compra este procesada..</h2>
                <br />
                <h4 style={{ textAlign: 'center' }}> Te pedimos que tengas paciencia, estamos trabajando en ello.</h4>
                <br />
            </div>
        </div>
    );
}

export default CompraPendiente