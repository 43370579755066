export const Destacados = [

    {
        id: "Pc Oficina Nsx Core I5 10400 Ram 8gb Ssd 512gb Gabinete Kit",
        img: "https://http2.mlstatic.com/D_NQ_NP_832360-MLA52142132677_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1214333627-pc-oficina-nsx-core-i5-10400-ram-8gb-ssd-512gb-gabinete-kit-_JM#position=18&search_layout=stack&type=item&tracking_id=7a66fb4a-915a-4b4d-b4a9-e50af7de8741"
    },
    {
        id: "Pc Nsx Desktop I3-12100 8gb Ram Ssd240gb Free Dos",
        img: "https://http2.mlstatic.com/D_NQ_NP_769856-MLA51837005393_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1186069352-pc-nsx-desktop-i3-12100-8gb-ram-ssd240gb-free-dos-_JM#position=19&search_layout=stack&type=item&tracking_id=7a66fb4a-915a-4b4d-b4a9-e50af7de8741"
    },
    {
        id: "Pc Nsx Desktop I5-10400 Ssd240gb Freedos",
        img: "https://http2.mlstatic.com/D_NQ_NP_921221-MLA51837378453_102022-O.webp",
        url: "https://www.nsx.com.ar/MLA-1186095154-pc-nsx-desktop-i5-10400-ssd240gb-freedos-_JM#position=20&search_layout=stack&type=item&tracking_id=7a66fb4a-915a-4b4d-b4a9-e50af7de8741"
    }
]