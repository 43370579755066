import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';

const CompraFallida = () => {
    // const location = useLocation();
    // const [paymentInfo, setPaymentInfo] = useState(null);

    // useEffect(() => {
    //     const searchParams = new URLSearchParams(location.search);
    //     const paymentId = searchParams.get('paymentId');
    //     const status = searchParams.get('status');


    //     if (paymentId && status) {
    //         setPaymentInfo({ paymentId, status });
    //     }
    // }, [location.search]);

    return (
        <div>

            {/* {paymentInfo && ( */}
            <div style={{ marginTop: '2em' }}>
                <h1 style={{ textAlign: 'center' }}>LO SENTIMOS!!!!</h1>
                <h2 style={{ textAlign: 'center' }}>Su pago no se ha podido completar satisfactoriamente.</h2>
                <br />
                <h4 style={{ textAlign: 'center' }}> Te pedimos que tengas paciencia, estamos trabajando en ello.</h4>
                <br />
                {/* <h3 style={{ textAlign: 'center', textDecoration: 'underline' }} >ID de Pago: {paymentInfo.paymentId}</h3>
                    <h4 style={{ textAlign: 'center' }}>Estado: {paymentInfo.status}</h4> */}
            </div>
            {/* )} */}
        </div>
    );
}

export default CompraFallida