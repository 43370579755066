// import "./ConfiguradorColumnasStyle.css";

// import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
// import { FaCheckCircle } from 'react-icons/fa';
// import React, { useContext, useEffect, useState } from 'react'

// import { ListadoContext } from '../ContextConfigurador/ListadoContext';
// import { MarcaProcesadorContext } from '../ContextConfigurador/MarcaProcesadorContext';
// import { PreconfiguradoContext } from '../ContextConfigurador/PreconfiguradoContext';
// import Swal from 'sweetalert2';
// import { motion } from "framer-motion";
// import { useParams } from 'react-router-dom';


// export default function PreconfigColumnaIzq({ minWidth, setMinWidth }) {
// 	const { infoDetallePrecioPreconfigurado, detallePreconfig, showComponents } = useContext(PreconfiguradoContext);
// 	const { infoDidMount, paso } = useContext(MarcaProcesadorContext);
// 	const { ocultarCategoria, listado } = useContext(ListadoContext);
// 	const { param } = useParams();
// 	const imagenCategoria = process.env.PUBLIC_URL + "/imagenes/imagenes-configurador/";
// 	const [show, setShow] = useState(true);

// 	const [navSize, setnavSize] = useState("60rem");
// 	const [navColor, setnavColor] = useState("#FFF");
// 	const [navVisibility, setNavVisibility] = useState('visible')

// 	const pedidoCompleto =
// 		listado.microprocesador.length > 0 &&
// 		listado.motherboard.length > 0 &&
// 		listado.ram.length > 0 &&
// 		listado.disco.length > 0 &&
// 		listado.gabinete.length > 0 &&
// 		listado.video.length > 0 &&
// 		listado.fuente.length > 0 &&
// 		listado.cooler.length > 0;

// 	const clasePedidoCompleto = pedidoCompleto ? "stage" : "opacity";

// 	console.log(pedidoCompleto, 'show co');


// 	const transitionValues = {
// 		// duration: 0.8,
// 		repeat: Infinity,
// 		repeatType: "reverse"
// 	};

// 	const listenScrollEvent = () => {
// 		window.scrollY > 10 ? setnavColor("#ffffff ") : setnavColor("#ffffff ");
// 		window.scrollY > 10 ? setnavSize("15rem") : setnavSize("15rem");
// 		window.scrollY > 300 ? setNavVisibility("hidden") : setNavVisibility("visible");
// 	};
// 	useEffect(() => {
// 		window.addEventListener("scroll", listenScrollEvent);
// 		return () => {
// 			window.removeEventListener("scroll", listenScrollEvent);
// 		};
// 	}, []);

// 	// Tooltips para cada paso 

// 	// const tooltipMicroprocesador = (props) => (
// 	// 	<Tooltip id="button-tooltip" {...props}>
// 	// 		Microprocesador
// 	// 	</Tooltip>
// 	// );

// 	// const tooltipMotherboard = (props) => (
// 	// 	<Tooltip id="button-tooltip" {...props}>
// 	// 		Motherboard
// 	// 	</Tooltip>
// 	// );

// 	// const tooltipRam = (props) => (
// 	// 	<Tooltip id="button-tooltip" {...props}>
// 	// 		Ram
// 	// 	</Tooltip>
// 	// );

// 	// const tooltipDisco = (props) => (
// 	// 	<Tooltip id="button-tooltip" {...props}>
// 	// 		Disco
// 	// 	</Tooltip>
// 	// );

// 	// const tooltipGabinete = (props) => (
// 	// 	<Tooltip id="button-tooltip" {...props}>
// 	// 		Gabinete
// 	// 	</Tooltip>
// 	// );

// 	// const tooltipVideo = (props) => (
// 	// 	<Tooltip id="button-tooltip" {...props}>
// 	// 		Placa de video
// 	// 	</Tooltip>
// 	// );

// 	// const tooltipFuente = (props) => (
// 	// 	<Tooltip id="button-tooltip" {...props}>
// 	// 		Fuente
// 	// 	</Tooltip>
// 	// );

// 	// const tooltipArmado = (props) => (
// 	// 	<Tooltip id="button-tooltip" {...props}>
// 	// 		Armado
// 	// 	</Tooltip>
// 	// );

// 	// const tooltipCooler = (props) => (
// 	// 	<Tooltip id="button-tooltip" {...props}>
// 	// 		Cooler
// 	// 	</Tooltip>
// 	// );

// 	// const tooltipLicencia = (props) => (
// 	// 	<Tooltip id="button-tooltip" {...props}>
// 	// 		Licencia
// 	// 	</Tooltip>
// 	// );

// 	// const tooltipMonitor = (props) => (
// 	// 	<Tooltip id="button-tooltip" {...props}>
// 	// 		Monitor
// 	// 	</Tooltip>
// 	// );

// 	// const tooltipTeclado = (props) => (
// 	// 	<Tooltip id="button-tooltip" {...props}>
// 	// 		Teclado
// 	// 	</Tooltip>
// 	// );

// 	// const tooltipMouse = (props) => (
// 	// 	<Tooltip id="button-tooltip" {...props}>
// 	// 		Mouse
// 	// 	</Tooltip>
// 	// );

// 	// const tooltipAuricular = (props) => (
// 	// 	<Tooltip id="button-tooltip" {...props}>
// 	// 		Auricular
// 	// 	</Tooltip>
// 	// );

// 	// const tooltipSilla = (props) => (
// 	// 	<Tooltip id="button-tooltip" {...props}>
// 	// 		Silla
// 	// 	</Tooltip>
// 	// );

// 	// Alerta puedes finalizar tu compra

// 	const alertaFinalizacionCompra = () => {
// 		Swal.fire('Perfecto! A partir de aca podes finalizar tu compra.')
// 	}

// 	// -------------------------------------------------------------------

// 	const handeClick = (/* url,  */component) => {
// 		if (listado.microprocesador.length > 0 && listado.motherboard.length > 0 && listado.ram.length > 0 &&
// 			listado.disco.length > 0 && listado.gabinete.length > 0 && listado.video.length > 0 && listado.fuente.length > 0 && listado.cooler.length > 0

// 		) {
// 			setShow(!show)
// 			infoDidMount(/* url, */ component);
// 		}
// 	}

// 	//useEffect(() => {
// 	//	if (paso === "motherboard") {
// 	//		handeClick("armado");
// 	//	}
// 	//}, [paso])

// 	useEffect(() => {
// 		infoDetallePrecioPreconfigurado(param);
// 	}, [infoDetallePrecioPreconfigurado, param, detallePreconfig, imagenCategoria])

// 	console.log(minWidth);

// 	// ----------------------------- Creo funcion para mostrar los componentes en vista celular ------------------------





// 	// ------------------------------------------------------------------------------------------------------------------

// 	return (

// 		<motion.div className='configurador-izq' >
// 			<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1000 }} transition={{ delay: 0.5, duration: 0.5 }} className="containerconfig"
// 				style={!minWidth ? { //SI EL ANCHO DE PANTALLA ES MENOR A 768 
// 					backgroundColor: navColor,
// 					height: navSize,
// 					visibility: navVisibility,
// 					transition: "all 0.5s"
// 				}
// 					: { //SINO
// 						backgroundColor: navColor,
// 						visibility: navVisibility,
// 						transition: "all 0.5s"
// 					}}>
// 				<motion.div className="row__categorias  mt-3"
// 					id={showComponents === false ? 'componentesAElegirHidden' : "componentesAElegir"}
// 					initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5, duration: 0.5 }}>

// 					<Col md={3} lg={3} sm={3} xs={3}
// 						className={`traigo-datos  m-1 my-lg-1 ${clasePedidoCompleto}  ${ocultarCategoria("microprocesador", param)}`}
// 						id="microprocesador" onClick={() => handeClick("microprocesador")} >
// 						{listado.microprocesador.length > 0 && <div className="iconContainer">
// 							<FaCheckCircle size={'1.5em'} color="green" />
// 						</div>}
// 						{/* <OverlayTrigger
// 							placement="top"
// 							delay={{ show: 250, hide: 400 }}
// 							overlay={tooltipMicroprocesador} style={{zIndex:"1000000"}}> */}

// 						{
// 							paso === 'microprocesador' ?
// 								<motion.div className={paso === 'microprocesador' ? 'stage' : null}
// 									transition={!minWidth && {
// 										y: transitionValues,
// 										width: transitionValues,
// 										height: transitionValues
// 									}}
// 									animate={!minWidth && {
// 										y: ["-0.5rem", "0.5rem"],
// 										width: ["5rem", "5rem", "4.4rem"],
// 										height: ["5rem", "5rem", "4.5rem"]
// 									}}>

// 									<motion.div whileHover={{ scale: 1.1 }}
// 										transition={!minWidth && { type: "spring", stiffness: 400, damping: 10 }}
// 										style={{ display: "flex", justifyContent: "center" }}>

// 										<img
// 											className="imgPaso d-xl-block microprocesadorImg" src={`${imagenCategoria}microprocesador.png`} alt="" />
// 										{/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-2 h-2">
// 												<path strokeLinecap="round" strokeLinejoin="round" d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 0 0 2.25-2.25V6.75a2.25 2.25 0 0 0-2.25-2.25H6.75A2.25 2.25 0 0 0 4.5 6.75v10.5a2.25 2.25 0 0 0 2.25 2.25Zm.75-12h9v9h-9v-9Z" />
// 											</svg> */}


// 									</motion.div>
// 								</motion.div> : <motion.div className={paso === 'microprocesador' ? 'stage' : 'selected'}>
// 									<motion.div whileHover={{
// 										scale: 1.1,
// 										textShadow: "0px 0px 4px gray"
// 									}} >
// 										<img
// 											className="imgPaso d-xl-block microprocesadorImg" src={`${imagenCategoria}microprocesador.png`} alt="" />
// 									</motion.div>
// 								</motion.div>
// 						}
// 						{/* </ OverlayTrigger> */}
// 					</Col>
// 					<Col md={3} lg={3} sm={3} xs={3}
// 						className={`traigo-datos  m-1 my-lg-1 ${clasePedidoCompleto} ${!listado.microprocesador.length > 0 && ocultarCategoria("motherboard", param)}`}
// 						id="motherboard" onClick={() => handeClick("motherboard")}>
// 						{listado.motherboard.length > 0 && <div className="iconContainer">
// 							<FaCheckCircle size={'1.5em'} color="green" />
// 						</div>}
// 						{/* <OverlayTrigger
// 							placement="top"
// 							delay={{ show: 250, hide: 400 }}
// 							overlay={tooltipMotherboard} style={{zIndex:"1000000"}}> */}
// 						{
// 							paso === 'motherboard' ?
// 								<motion.div className={paso === 'motherboard' ? 'stage' : null} transition={!minWidth && {
// 									y: transitionValues,
// 									width: transitionValues,
// 									height: transitionValues
// 								}}
// 									animate={!minWidth && {
// 										y: ["-0.5rem", "0.5rem"],
// 										width: ["5rem", "5rem", "5.4rem"],
// 										height: ["5rem", "5rem", "4.5rem"]
// 									}}
// 								>
// 									<motion.div whileHover={{
// 										scale: 1.1,
// 										textShadow: "0px 0px 4px gray"

// 									}} transition={!minWidth && { type: "spring", stiffness: 400, damping: 10 }}>
// 										<img className="imgPaso d-xl-block motherboardImg" src={`${imagenCategoria}motherboard.png`} alt=""
// 										/>
// 									</motion.div>
// 								</motion.div>
// 								:
// 								<motion.div className={paso === 'motherboard' ? 'stage' : null} >
// 									<motion.div whileHover={{
// 										scale: 1.1,
// 										textShadow: "0px 0px 4px gray"
// 									}} transition={!minWidth && { type: "spring", stiffness: 400, damping: 10 }}>
// 										<img className="imgPaso d-xl-block motherboardImg" src={`${imagenCategoria}motherboard.png`} alt=""
// 										/>
// 									</motion.div>
// 								</motion.div>
// 						}

// 						{/* </ OverlayTrigger> */}

// 					</Col>
// 					<Col md={3} lg={3} sm={3} xs={3}
// 						className={`traigo-datos  m-1 my-lg-1 ${clasePedidoCompleto}  ${!listado.motherboard.length > 0 && ocultarCategoria("ram", param)}`}
// 						id="ram" onClick={() => handeClick("ram")}>
// 						{listado.ram.length > 0 && <div className="iconContainer">
// 							<FaCheckCircle size={'1.5em'} color="green" />
// 						</div>}
// 						{/* <OverlayTrigger
// 							placement="top"
// 							delay={{ show: 250, hide: 400 }}
// 							overlay={tooltipRam} style={{zIndex:"1000000"}}> */}
// 						{
// 							paso === 'ram' ?
// 								<motion.div whileHover={{
// 									scale: 1.1,
// 									textShadow: "0px 0px 4px gray"
// 								}} className={paso === 'ram' ? 'stage' : null}
// 									transition={!minWidth && {
// 										y: transitionValues,
// 										width: transitionValues,
// 										height: transitionValues
// 									}}
// 									animate={!minWidth && {
// 										y: ["-0.5rem", "0.5rem"],
// 										width: ["5rem", "5rem", "5.4rem"],
// 										height: ["5rem", "5rem", "4.5rem"]
// 									}}>
// 									<motion.div>
// 										<img className="imgPaso d-xl-block  ramImg" src={`${imagenCategoria}ram.png`} alt=""
// 										/>
// 									</motion.div>
// 								</motion.div> :
// 								<motion.div whileHover={{
// 									scale: 1.1,
// 									textShadow: "0px 0px 4px gray"
// 								}} className={paso === 'ram' ? 'stage' : null}>
// 									<motion.div>
// 										<img className="imgPaso d-xl-block  ramImg" src={`${imagenCategoria}ram.png`} alt=""
// 										/>
// 									</motion.div>
// 								</motion.div>
// 						}
// 						{/* </ OverlayTrigger> */}
// 					</Col>

// 					<Col md={3} lg={3} sm={3} xs={3}
// 						className={`traigo-datos  m-1 my-lg-1 ${clasePedidoCompleto} ${!listado.ram.length > 0 && ocultarCategoria("disco", param)}`}
// 						id="disco" onClick={() => handeClick("disco")}>
// 						{listado.disco.length > 0 && <div className="iconContainer">
// 							<FaCheckCircle size={'1.5em'} color="green" />
// 						</div>}
// 						{/* <OverlayTrigger
// 							placement="top"
// 							delay={{ show: 250, hide: 400 }}
// 							overlay={tooltipDisco} style={{zIndex:"1000000"}}> */}
// 						{
// 							paso === 'disco' ?
// 								<motion.div whileHover={{
// 									scale: 1.1,
// 									textShadow: "0px 0px 4px gray"
// 								}} className={paso === 'disco' ? 'stage' : null} transition={!minWidth && {
// 									y: transitionValues,
// 									width: transitionValues,
// 									height: transitionValues
// 								}}
// 									animate={!minWidth && {
// 										y: ["-0.5rem", "0.5rem"],
// 										width: ["5rem", "5rem", "5.4rem"],
// 										height: ["5rem", "5rem", "4.5rem"]
// 									}}>
// 									<motion.div>
// 										<img className="imgPaso d-xl-block  discoImg" src={`${imagenCategoria}disco.png`} alt=""
// 										/>
// 									</motion.div>
// 								</motion.div> :
// 								<motion.div whileHover={{
// 									scale: 1.1,
// 									textShadow: "0px 0px 4px gray"
// 								}} className={paso === 'disco' ? 'stage' : null}>
// 									<motion.div>
// 										<img className="imgPaso d-xl-block  discoImg" src={`${imagenCategoria}disco.png`} alt=""
// 										/>
// 									</motion.div>
// 								</motion.div>
// 						}
// 						{/* </ OverlayTrigger> */}
// 					</Col>
// 					<Col md={3} lg={3} sm={3} xs={3}
// 						className={`traigo-datos  m-1 my-lg-1 ${clasePedidoCompleto} ${!listado.disco.length > 0 && ocultarCategoria("gabinete", param)}`}
// 						id="gabinete" onClick={() => handeClick("gabinete")}>
// 						{listado.gabinete.length > 0 && <div className="iconContainer">
// 							<FaCheckCircle size={'1.5em'} color="green" />
// 						</div>}
// 						{/* <OverlayTrigger
// 							placement="top"
// 							delay={{ show: 250, hide: 400 }}
// 							overlay={tooltipGabinete} style={{zIndex:"1000000"}}> */}
// 						{
// 							paso === 'gabinete' ?
// 								<motion.div whileHover={{
// 									scale: 1.1,
// 									textShadow: "0px 0px 4px gray"
// 								}} className={paso === 'gabinete' ? 'stage' : null} transition={!minWidth && {
// 									y: transitionValues,
// 									width: transitionValues,
// 									height: transitionValues
// 								}}
// 									animate={!minWidth && {
// 										y: ["-0.5rem", "0.5rem"],
// 										width: ["5rem", "5rem", "5.4rem"],
// 										height: ["5rem", "5rem", "4.5rem"]
// 									}}>
// 									<motion.div>
// 										<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block  gabineteImg" src={`${imagenCategoria}gabinete.png`} alt=""
// 										/>
// 									</motion.div>
// 								</motion.div> :
// 								<motion.div whileHover={{
// 									scale: 1.1,
// 									textShadow: "0px 0px 4px gray"
// 								}} className={paso === 'gabinete' ? 'stage' : null}>
// 									<motion.div>
// 										<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block  gabineteImg" src={`${imagenCategoria}gabinete.png`} alt=""
// 										/>
// 									</motion.div>
// 								</motion.div>
// 						}
// 						{/* </ OverlayTrigger> */}
// 					</Col>
// 					<Col md={3} lg={3} sm={3} xs={3}
// 						className={`traigo-datos  m-1 my-lg-1 ${clasePedidoCompleto}  ${!listado.gabinete.length > 0 && ocultarCategoria("video", param)}`}
// 						id="video" onClick={() => handeClick("video")}>
// 						{listado.video.length > 0 && <div className="iconContainer">
// 							<FaCheckCircle size={'1.5em'} color="green" />
// 						</div>}
// 						{/* <OverlayTrigger
// 							placement="top"
// 							delay={{ show: 250, hide: 400 }}
// 							overlay={tooltipVideo} style={{zIndex:"1000000"}}> */}
// 						{
// 							paso === 'video' ?
// 								<motion.div whileHover={{
// 									scale: 1.1,
// 									textShadow: "0px 0px 4px gray"
// 								}} className={paso === 'video' ? 'stage' : null} transition={!minWidth && {
// 									y: transitionValues,
// 									width: transitionValues,
// 									height: transitionValues
// 								}}
// 									animate={!minWidth && {
// 										y: ["-0.5rem", "0.5rem"],
// 										width: ["5rem", "5rem", "5.4rem"],
// 										height: ["5rem", "5rem", "4.5rem"]
// 									}}>
// 									<motion.div>
// 										<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block videoImg" src={`${imagenCategoria}video.png`} alt=""
// 										/>
// 									</motion.div>
// 								</motion.div> :
// 								<motion.div whileHover={{
// 									scale: 1.1,
// 									textShadow: "0px 0px 4px gray"
// 								}} className={paso === 'video' ? 'stage' : null}>
// 									<motion.div>
// 										<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block videoImg" src={`${imagenCategoria}video.png`} alt=""
// 										/>
// 									</motion.div>
// 								</motion.div>
// 						}
// 						{/* </ OverlayTrigger> */}
// 					</Col>
// 					<Col md={3} lg={3} sm={3} xs={3}
// 						className={`traigo-datos  m-1 my-lg-1 ${clasePedidoCompleto} ${!listado.video.length > 0 && ocultarCategoria("fuente", param)}`}
// 						id="fuente" onClick={() => handeClick("fuente")}>
// 						{listado.fuente.length > 0 && <div className="iconContainer">
// 							<FaCheckCircle size={'1.5em'} color="green" />
// 						</div>}
// 						{/* <OverlayTrigger
// 							placement="top"
// 							delay={{ show: 250, hide: 400 }}
// 							overlay={tooltipFuente} style={{zIndex:"1000000"}}> */}
// 						{
// 							paso === 'fuente' ?
// 								<motion.div whileHover={{
// 									scale: 1.1,
// 									textShadow: "0px 0px 4px gray"
// 								}} className={paso === 'fuente' ? 'stage' : null} transition={!minWidth && {
// 									y: transitionValues,
// 									width: transitionValues,
// 									height: transitionValues
// 								}}
// 									animate={!minWidth && {
// 										y: ["-0.5rem", "0.5rem"],
// 										width: ["5rem", "5rem", "5.4rem"],
// 										height: ["5rem", "5rem", "4.5rem"]
// 									}}>
// 									<motion.div>
// 										<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block fuenteImg" src={`${imagenCategoria}fuente.png`} alt=""
// 										/>
// 									</motion.div>
// 								</motion.div> :
// 								<motion.div whileHover={{
// 									scale: 1.1,
// 									textShadow: "0px 0px 4px gray"
// 								}} className={paso === 'fuente' ? 'stage' : null}>
// 									<motion.div>
// 										<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block fuenteImg" src={`${imagenCategoria}fuente.png`} alt=""
// 										/>
// 									</motion.div>
// 								</motion.div>
// 						}
// 						{/* </ OverlayTrigger> */}
// 					</Col>

// 					<div style={{ display: 'none' }}>
// 						<Col md={3} lg={3} sm={3} xs={3}
// 							className={`traigo-datos  m-1 my-lg-1 ${clasePedidoCompleto}  ${!listado.fuente.length > 0 && ocultarCategoria("armado", param)}`}
// 							id="armado" onClick={() => {
// 								const handleClick = handleClick("armado");
// 								const handleAlert = () => alertaFinalizacionCompra();
// 								handleClick();
// 								handleAlert();
// 							}}>
// 							{listado.armado.length > 0 && <div className="iconContainer">
// 								<FaCheckCircle size={'1.5em'} color="green" />
// 							</div>}

// 							{
// 								paso === 'armado' ?
// 									<motion.div whileHover={{
// 										scale: 1.1,
// 										textShadow: "0px 0px 4px gray"
// 									}} className={paso === 'armado' ? 'stage' : null} transition={!minWidth && {
// 										y: transitionValues,
// 										width: transitionValues,
// 										height: transitionValues
// 									}}
// 										animate={!minWidth && {
// 											y: ["-0.5rem", "0.5rem"],
// 											width: ["5rem", "5rem", "5.4rem"],
// 											height: ["5rem", "5rem", "4.5rem"]
// 										}}>
// 										<motion.div>
// 											<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block armadoImg" src={`${imagenCategoria}armado.png`} alt=""
// 											/>
// 										</motion.div>
// 									</motion.div> :
// 									<motion.div whileHover={{
// 										scale: 1.1,
// 										textShadow: "0px 0px 4px gray"
// 									}} className={paso === 'armado' ? 'stage' : null}>
// 										<motion.div>
// 											<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block armadoImg" src={`${imagenCategoria}armado.png`} alt=""
// 											/>
// 										</motion.div>
// 									</motion.div>
// 							}
// 						</Col>

// 					</div>

// 					<Col md={3} lg={3} sm={3} xs={3}
// 						className={`traigo-datos  m-1 my-lg-1 ${clasePedidoCompleto}  ${!listado.fuente.length > 0 && ocultarCategoria("cooler", param)}`}
// 						id="cooler" onClick={() => {
// 							const handleClick = handleClick("cooler");
// 							handleClick();
// 						}}>
// 						{listado.cooler.length > 0 && <div className="iconContainer">
// 							<FaCheckCircle size={'1.5em'} color="green" />
// 						</div>}
// 						{/* <OverlayTrigger
// 							placement="top"
// 							delay={{ show: 250, hide: 400 }}
// 							overlay={tooltipCooler} style={{zIndex:"1000000"}}> */}
// 						{
// 							paso === 'cooler' ? <motion.div whileHover={{
// 								scale: 1.1,
// 								textShadow: "0px 0px 4px gray"
// 							}} className={paso === 'cooler' ? 'stage' : null} transition={!minWidth && {
// 								y: transitionValues,
// 								width: transitionValues,
// 								height: transitionValues
// 							}}
// 								animate={!minWidth && {
// 									y: ["-0.5rem", "0.5rem"],
// 									width: ["5rem", "5rem", "5.4rem"],
// 									height: ["5rem", "5rem", "4.5rem"]
// 								}}>
// 								<motion.div>
// 									<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block  coolerImg" src={`${imagenCategoria}cooler.png`} alt=""
// 									/>
// 								</motion.div>
// 							</motion.div> :
// 								<motion.div whileHover={{
// 									scale: 1.1,
// 									textShadow: "0px 0px 4px gray"
// 								}} className={paso === 'cooler' ? 'stage' : null}>
// 									<motion.div>
// 										<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block  coolerImg" src={`${imagenCategoria}cooler.png`} alt=""
// 										/>
// 									</motion.div>
// 								</motion.div>
// 						}
// 						{/* </ OverlayTrigger> */}
// 					</Col>

// 					<div style={{ display: 'none' }}>
// 						<Col md={3} lg={3} sm={3} xs={3}
// 							className={`traigo-datos  m-1 my-lg-1 ${clasePedidoCompleto}  ${!listado.fuente.length > 0 && ocultarCategoria("licencia", param)}`}
// 							id="licencia" onClick={() => handeClick("licencia")}>
// 							{listado.licencia.length > 0 && <div className="iconContainer">
// 								<FaCheckCircle size={'1.5em'} color="green" />
// 							</div>}
// 							{/* <OverlayTrigger
// 							placement="top"
// 							delay={{ show: 250, hide: 400 }}
// 							overlay={tooltipLicencia} style={{zIndex:"1000000"}}> */}
// 							{
// 								paso === 'licencia' ?
// 									<motion.div whileHover={{
// 										scale: 1.1,
// 										textShadow: "0px 0px 4px gray"
// 									}} className={paso === 'licencia' ? 'stage' : null} transition={!minWidth && {
// 										y: transitionValues,
// 										width: transitionValues,
// 										height: transitionValues
// 									}}
// 										animate={!minWidth && {
// 											y: ["-0.5rem", "0.5rem"],
// 											width: ["5rem", "5rem", "5.4rem"],
// 											height: ["5rem", "5rem", "4.5rem"]
// 										}}>
// 										<motion.div>
// 											<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block  licenciaImg" src={`${imagenCategoria}licencia.png`} alt=""
// 											/>
// 										</motion.div>
// 									</motion.div> :
// 									<motion.div whileHover={{
// 										scale: 1.1,
// 										textShadow: "0px 0px 4px gray"
// 									}} className={paso === 'licencia' ? 'stage' : null}>
// 										<motion.div>
// 											<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block  licenciaImg" src={`${imagenCategoria}licencia.png`} alt=""
// 											/>
// 										</motion.div>
// 									</motion.div>
// 							}
// 							{/* </ OverlayTrigger> */}
// 						</Col>
// 						<Col md={3} lg={3} sm={3} xs={3}
// 							className={`traigo-datos  m-1 my-lg-1 ${clasePedidoCompleto} ${!listado.fuente.length > 0 && ocultarCategoria("monitor", param)}`}
// 							id="monitor"
// 							onClick={() => handeClick("monitor")}>
// 							{listado.monitor.length > 0 && <div className="iconContainer">
// 								<FaCheckCircle size={'1.5em'} color="green" />
// 							</div>}
// 							{/* <OverlayTrigger
// 							placement="top"
// 							delay={{ show: 250, hide: 400 }}
// 							overlay={tooltipMonitor} style={{zIndex:"1000000"}}> */}
// 							{
// 								paso === 'monitor' ? <motion.div whileHover={{
// 									scale: 1.1,
// 									textShadow: "0px 0px 4px gray"
// 								}} className={paso === 'monitor' ? 'stage' : null} transition={!minWidth && {
// 									y: transitionValues,
// 									width: transitionValues,
// 									height: transitionValues
// 								}}
// 									animate={!minWidth && {
// 										y: ["-0.5rem", "0.5rem"],
// 										width: ["5rem", "5rem", "5.4rem"],
// 										height: ["5rem", "5rem", "4.5rem"]
// 									}}>
// 									<motion.div>
// 										<img whileHover={{ scale: 1.2 }} whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block  monitorImg" src={`${imagenCategoria}monitor.png`} alt=""
// 										/>
// 									</motion.div>
// 								</motion.div> :
// 									<motion.div whileHover={{
// 										scale: 1.1,
// 										textShadow: "0px 0px 4px gray"
// 									}} className={paso === 'monitor' ? 'stage' : null}>
// 										<motion.div>
// 											<img whileHover={{ scale: 1.2 }} whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block  monitorImg" src={`${imagenCategoria}monitor.png`} alt=""
// 											/>
// 										</motion.div>
// 									</motion.div>
// 							}
// 							{/* </ OverlayTrigger> */}
// 						</Col>
// 						<Col md={3} lg={3} sm={3} xs={3}
// 							className={`traigo-datos  m-1 my-lg-1  ${clasePedidoCompleto} ${!listado.fuente.length > 0 && ocultarCategoria("teclado", param)}`}
// 							id="teclado" onClick={() => handeClick("teclado")}>
// 							{listado.teclado.length > 0 && <div className="iconContainer">
// 								<FaCheckCircle size={'1.5em'} color="green" />
// 							</div>}
// 							{/* <OverlayTrigger
// 							placement="top"
// 							delay={{ show: 250, hide: 400 }}
// 							overlay={tooltipTeclado} style={{zIndex:"1000000"}}> */}
// 							{
// 								paso === 'teclado' ?
// 									<motion.div whileHover={{
// 										scale: 1.1,
// 										textShadow: "0px 0px 4px gray"
// 									}} className={paso === 'teclado' ? 'stage' : null} transition={!minWidth && {
// 										y: transitionValues,
// 										width: transitionValues,
// 										height: transitionValues
// 									}}
// 										animate={!minWidth && {
// 											y: ["-0.5rem", "0.5rem"],
// 											width: ["5rem", "5rem", "5.4rem"],
// 											height: ["5rem", "5rem", "4.5rem"]
// 										}}>
// 										<motion.div>
// 											<img whileHover={{ scale: 1.2 }} whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block  tecladoImg" src={`${imagenCategoria}teclado.png`} alt=""
// 											/>
// 										</motion.div>
// 									</motion.div> :
// 									<motion.div whileHover={{
// 										scale: 1.1,
// 										textShadow: "0px 0px 4px gray"
// 									}} className={paso === 'teclado' ? 'stage' : null}>
// 										<motion.div>
// 											<img whileHover={{ scale: 1.2 }} whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block  tecladoImg" src={`${imagenCategoria}teclado.png`} alt=""
// 											/>
// 										</motion.div>
// 									</motion.div>
// 							}
// 							{/* </ OverlayTrigger> */}
// 						</Col>
// 						<Col md={3} lg={3} sm={3} xs={3}
// 							className={`traigo-datos  m-1 my-lg-1  ${clasePedidoCompleto} ${!listado.fuente.length > 0 && ocultarCategoria("mouse", param)}`}
// 							id="mouse" onClick={() => handeClick("mouse")}>
// 							{listado.mouse.length > 0 && <div className="iconContainer">
// 								<FaCheckCircle size={'1.5em'} color="green" />
// 							</div>}
// 							{/* <OverlayTrigger
// 							placement="top"
// 							delay={{ show: 250, hide: 400 }}
// 							overlay={tooltipMouse} style={{zIndex:"1000000"}}> */}
// 							{
// 								paso === 'mouse' ?
// 									<motion.div whileHover={{
// 										scale: 1.1,
// 										textShadow: "0px 0px 4px gray"
// 									}} className={paso === 'mouse' ? 'stage' : null} transition={!minWidth && {
// 										y: transitionValues,
// 										width: transitionValues,
// 										height: transitionValues
// 									}}
// 										animate={!minWidth && {
// 											y: ["-0.5rem", "0.5rem"],
// 											width: ["5rem", "5rem", "5.4rem"],
// 											height: ["5rem", "5rem", "4.5rem"]
// 										}} >
// 										<motion.div>
// 											<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block  mouseImg" src={`${imagenCategoria}mouses.png`} alt=""
// 											/>
// 										</motion.div>
// 									</motion.div> :
// 									<motion.div whileHover={{
// 										scale: 1.1,
// 										textShadow: "0px 0px 4px gray"
// 									}} className={paso === 'mouse' ? 'stage' : null}>
// 										<motion.div>
// 											<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block  mouseImg" src={`${imagenCategoria}mouses.png`} alt=""
// 											/>
// 										</motion.div>
// 									</motion.div>
// 							}
// 							{/* </ OverlayTrigger> */}
// 						</Col>
// 						<Col md={3} lg={3} sm={3} xs={3}
// 							className={`traigo-datos  m-1 my-lg-1  ${clasePedidoCompleto}  ${!listado.fuente.length > 0 && ocultarCategoria("auricular", param)}`}
// 							id="auricular" onClick={() => handeClick("auricular")}>
// 							{listado.auricular.length > 0 && <div className="iconContainer">
// 								<FaCheckCircle size={'1.5em'} color="green" />
// 							</div>}
// 							{/* <OverlayTrigger
// 							placement="top"
// 							delay={{ show: 250, hide: 400 }}
// 							overlay={tooltipAuricular} style={{zIndex:"1000000"}}> */}
// 							{
// 								paso === 'auricular' ?
// 									<motion.div whileHover={{
// 										scale: 1.1,
// 										textShadow: "0px 0px 4px gray"
// 									}} className={paso === 'auricular' ? 'stage' : null} transition={!minWidth && {
// 										y: transitionValues,
// 										width: transitionValues,
// 										height: transitionValues
// 									}}
// 										animate={!minWidth && {
// 											y: ["-0.5rem", "0.5rem"],
// 											width: ["5rem", "5rem", "5.4rem"],
// 											height: ["5rem", "5rem", "4.5rem"]
// 										}}>
// 										<motion.div>
// 											<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block auricularImg" src={`${imagenCategoria}auricular.png`} alt=""
// 											/>
// 										</motion.div>
// 									</motion.div> : <motion.div whileHover={{
// 										scale: 1.1,
// 										textShadow: "0px 0px 4px gray"
// 									}} className={paso === 'auricular' ? 'stage' : null}>
// 										<motion.div>
// 											<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block auricularImg" src={`${imagenCategoria}auricular.png`} alt=""
// 											/>
// 										</motion.div>
// 									</motion.div>
// 							}
// 							{/* </ OverlayTrigger> */}
// 						</Col>
// 						<Col md={3} lg={3} sm={3} xs={3}
// 							className={`traigo-datos  m-1 my-lg-1 ${clasePedidoCompleto}  ${!listado.fuente.length > 0 && ocultarCategoria("silla", param)}`}
// 							id="silla" onClick={() => handeClick("silla")}>
// 							{listado.silla.length > 0 && <div className="iconContainer">
// 								<FaCheckCircle size={'1.5em'} color="green" />
// 							</div>}
// 							{/* <OverlayTrigger
// 							placement="top"
// 							delay={{ show: 250, hide: 400 }}
// 							overlay={tooltipSilla} style={{zIndex:"1000000"}}> */}
// 							{
// 								paso === 'silla' ?
// 									<motion.div whileHover={{
// 										scale: 1.1,
// 										textShadow: "0px 0px 4px gray"
// 									}} className={paso === 'silla' ? 'stage' : null} transition={!minWidth && {
// 										y: transitionValues,
// 										width: transitionValues,
// 										height: transitionValues
// 									}}
// 										animate={!minWidth && {
// 											y: ["-0.5rem", "0.5rem"],
// 											width: ["5rem", "5rem", "5.4rem"],
// 											height: ["5rem", "5rem", "4.5rem"]
// 										}} >
// 										<motion.div>
// 											<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block sillaImg" src={`${imagenCategoria}silla.png`} alt=""
// 											/>
// 										</motion.div>
// 									</motion.div> :
// 									<motion.div whileHover={{
// 										scale: 1.1,
// 										textShadow: "0px 0px 4px gray"
// 									}} className={paso === 'silla' ? 'stage' : null}>
// 										<motion.div>
// 											<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block sillaImg" src={`${imagenCategoria}silla.png`} alt=""
// 											/>
// 										</motion.div>
// 									</motion.div>
// 							}
// 							{/* </ OverlayTrigger> */}
// 						</Col>
// 					</div>
// 				</motion.div>
// 			</motion.div>

// 		</motion.div>
// 	)
// }


import "./ConfiguradorColumnasStyle.css";

import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FaCheckCircle } from 'react-icons/fa';
import React, { useContext, useEffect, useState } from 'react'

import { ListadoContext } from '../ContextConfigurador/ListadoContext';
import { MarcaProcesadorContext } from '../ContextConfigurador/MarcaProcesadorContext';
import { PreconfiguradoContext } from '../ContextConfigurador/PreconfiguradoContext';
import Swal from 'sweetalert2';
import { motion } from "framer-motion";
import { useParams } from 'react-router-dom';


export default function PreconfigColumnaIzq({ minWidth, setMinWidth }) {
	const { infoDetallePrecioPreconfigurado, detallePreconfig, showComponents } = useContext(PreconfiguradoContext);
	const { infoDidMount, paso } = useContext(MarcaProcesadorContext);
	const { ocultarCategoria, listado } = useContext(ListadoContext);
	const { param } = useParams();
	const imagenCategoria = process.env.PUBLIC_URL + "/imagenes/imagenes-configurador/";
	const [show, setShow] = useState(true);

	const [navSize, setnavSize] = useState("60rem");
	const [navColor, setnavColor] = useState("#FFF");
	const [navVisibility, setNavVisibility] = useState('visible')

	const pedidoCompleto =
		listado.microprocesador.length > 0 &&
		listado.motherboard.length > 0 &&
		listado.ram.length > 0 &&
		listado.disco.length > 0 &&
		listado.gabinete.length > 0 &&
		listado.video.length > 0 &&
		listado.fuente.length > 0 &&
		listado.cooler.length > 0;

	const clasePedidoCompleto = pedidoCompleto ? "stage" : "opacity";

	console.log(pedidoCompleto, 'show co');


	const transitionValues = {
		// duration: 0.8,
		repeat: Infinity,
		repeatType: "reverse"
	};

	const listenScrollEvent = () => {
		window.scrollY > 10 ? setnavColor("#ffffff ") : setnavColor("#ffffff ");
		window.scrollY > 10 ? setnavSize("15rem") : setnavSize("15rem");
		window.scrollY > 300 ? setNavVisibility("hidden") : setNavVisibility("visible");
	};
	useEffect(() => {
		window.addEventListener("scroll", listenScrollEvent);
		return () => {
			window.removeEventListener("scroll", listenScrollEvent);
		};
	}, []);

	// Tooltips para cada paso 

	// const tooltipMicroprocesador = (props) => (
	// 	<Tooltip id="button-tooltip" {...props}>
	// 		Microprocesador
	// 	</Tooltip>
	// );

	// const tooltipMotherboard = (props) => (
	// 	<Tooltip id="button-tooltip" {...props}>
	// 		Motherboard
	// 	</Tooltip>
	// );

	// const tooltipRam = (props) => (
	// 	<Tooltip id="button-tooltip" {...props}>
	// 		Ram
	// 	</Tooltip>
	// );

	// const tooltipDisco = (props) => (
	// 	<Tooltip id="button-tooltip" {...props}>
	// 		Disco
	// 	</Tooltip>
	// );

	// const tooltipGabinete = (props) => (
	// 	<Tooltip id="button-tooltip" {...props}>
	// 		Gabinete
	// 	</Tooltip>
	// );

	// const tooltipVideo = (props) => (
	// 	<Tooltip id="button-tooltip" {...props}>
	// 		Placa de video
	// 	</Tooltip>
	// );

	// const tooltipFuente = (props) => (
	// 	<Tooltip id="button-tooltip" {...props}>
	// 		Fuente
	// 	</Tooltip>
	// );

	// const tooltipArmado = (props) => (
	// 	<Tooltip id="button-tooltip" {...props}>
	// 		Armado
	// 	</Tooltip>
	// );

	// const tooltipCooler = (props) => (
	// 	<Tooltip id="button-tooltip" {...props}>
	// 		Cooler
	// 	</Tooltip>
	// );

	// const tooltipLicencia = (props) => (
	// 	<Tooltip id="button-tooltip" {...props}>
	// 		Licencia
	// 	</Tooltip>
	// );

	// const tooltipMonitor = (props) => (
	// 	<Tooltip id="button-tooltip" {...props}>
	// 		Monitor
	// 	</Tooltip>
	// );

	// const tooltipTeclado = (props) => (
	// 	<Tooltip id="button-tooltip" {...props}>
	// 		Teclado
	// 	</Tooltip>
	// );

	// const tooltipMouse = (props) => (
	// 	<Tooltip id="button-tooltip" {...props}>
	// 		Mouse
	// 	</Tooltip>
	// );

	// const tooltipAuricular = (props) => (
	// 	<Tooltip id="button-tooltip" {...props}>
	// 		Auricular
	// 	</Tooltip>
	// );

	// const tooltipSilla = (props) => (
	// 	<Tooltip id="button-tooltip" {...props}>
	// 		Silla
	// 	</Tooltip>
	// );

	// Alerta puedes finalizar tu compra

	const alertaFinalizacionCompra = () => {
		Swal.fire('Perfecto! A partir de aca podes finalizar tu compra.')
	}

	// -------------------------------------------------------------------

	const handeClick = (/* url,  */component) => {
		if (listado.microprocesador.length > 0 && listado.motherboard.length > 0 && listado.ram.length > 0 &&
			listado.disco.length > 0 && listado.gabinete.length > 0 && listado.video.length > 0 && listado.fuente.length > 0 && listado.cooler.length > 0

		) {
			setShow(!show)
			infoDidMount(/* url, */ component);
		}
	}

	//useEffect(() => {
	//	if (paso === "motherboard") {
	//		handeClick("armado");
	//	}
	//}, [paso])

	useEffect(() => {
		infoDetallePrecioPreconfigurado(param);
	}, [infoDetallePrecioPreconfigurado, param, detallePreconfig, imagenCategoria])

	console.log(minWidth);

	// ----------------------------- Creo funcion para mostrar los componentes en vista celular ------------------------





	// ------------------------------------------------------------------------------------------------------------------

	return (

		<motion.div className='configurador-izq' >
			<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1000 }} transition={{ delay: 0.5, duration: 0.5 }} className="containerconfig"
				style={!minWidth ? { //SI EL ANCHO DE PANTALLA ES MENOR A 768 
					backgroundColor: navColor,
					height: navSize,
					visibility: navVisibility,
					transition: "all 0.5s"
				}
					: { //SINO
						backgroundColor: navColor,
						visibility: navVisibility,
						transition: "all 0.5s"
					}}>
				<motion.div className="row__categorias  mt-3"
					id={showComponents === false ? 'componentesAElegirHidden' : "componentesAElegir"}
					initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5, duration: 0.5 }}>

					<Col md={3} lg={3} sm={3} xs={3}
						className={`traigo-datos  m-1 my-lg-1 ${clasePedidoCompleto}  ${ocultarCategoria("microprocesador", param)}`}
						id="microprocesador" onClick={() => handeClick("microprocesador")} >
						{listado.microprocesador.length > 0 && <div className="iconContainer">
							<FaCheckCircle size={'1.5em'} color="green" />
						</div>}
						{/* <OverlayTrigger
							placement="top"
							delay={{ show: 250, hide: 400 }}
							overlay={tooltipMicroprocesador} style={{zIndex:"1000000"}}> */}

						{
							paso === 'microprocesador' ?
								<motion.div className={paso === 'microprocesador' ? 'stage' : null}
									transition={!minWidth && {
										y: transitionValues,
										width: transitionValues,
										height: transitionValues
									}}
									animate={!minWidth && {
										y: ["-0.5rem", "0.5rem"],
										width: ["5rem", "5rem", "4.4rem"],
										height: ["5rem", "5rem", "4.5rem"]
									}}>

									<motion.div whileHover={{ scale: 1.1 }}
										transition={!minWidth && { type: "spring", stiffness: 400, damping: 10 }}
										style={{ display: "flex", justifyContent: "center" }}>

										<img
											className="imgPaso d-xl-block microprocesadorImg" src={`${imagenCategoria}microprocesador.png`} alt="" />
										{/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-2 h-2">
												<path strokeLinecap="round" strokeLinejoin="round" d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 0 0 2.25-2.25V6.75a2.25 2.25 0 0 0-2.25-2.25H6.75A2.25 2.25 0 0 0 4.5 6.75v10.5a2.25 2.25 0 0 0 2.25 2.25Zm.75-12h9v9h-9v-9Z" />
											</svg> */}


									</motion.div>
								</motion.div> : <motion.div className={paso === 'microprocesador' ? 'stage' : 'selected'}>
									<motion.div whileHover={{
										scale: 1.1,
										textShadow: "0px 0px 4px gray"
									}} >
										<img
											className="imgPaso d-xl-block microprocesadorImg" src={`${imagenCategoria}microprocesador.png`} alt="" />
									</motion.div>
								</motion.div>
						}
						{/* </ OverlayTrigger> */}
					</Col>
					<Col md={3} lg={3} sm={3} xs={3}
						className={`traigo-datos  m-1 my-lg-1 ${clasePedidoCompleto} ${!listado.microprocesador.length > 0 && ocultarCategoria("motherboard", param)}`}
						id="motherboard" onClick={() => handeClick("motherboard")}>
						{listado.motherboard.length > 0 && <div className="iconContainer">
							<FaCheckCircle size={'1.5em'} color="green" />
						</div>}
						{/* <OverlayTrigger
							placement="top"
							delay={{ show: 250, hide: 400 }}
							overlay={tooltipMotherboard} style={{zIndex:"1000000"}}> */}
						{
							paso === 'motherboard' ?
								<motion.div className={paso === 'motherboard' ? 'stage' : null} transition={!minWidth && {
									y: transitionValues,
									width: transitionValues,
									height: transitionValues
								}}
									animate={!minWidth && {
										y: ["-0.5rem", "0.5rem"],
										width: ["5rem", "5rem", "5.4rem"],
										height: ["5rem", "5rem", "4.5rem"]
									}}
								>
									<motion.div whileHover={{
										scale: 1.1,
										textShadow: "0px 0px 4px gray"

									}} transition={!minWidth && { type: "spring", stiffness: 400, damping: 10 }}>
										<img className="imgPaso d-xl-block motherboardImg" src={`${imagenCategoria}motherboard.png`} alt=""
										/>
									</motion.div>
								</motion.div>
								:
								<motion.div className={paso === 'motherboard' ? 'stage' : null} >
									<motion.div whileHover={{
										scale: 1.1,
										textShadow: "0px 0px 4px gray"
									}} transition={!minWidth && { type: "spring", stiffness: 400, damping: 10 }}>
										<img className="imgPaso d-xl-block motherboardImg" src={`${imagenCategoria}motherboard.png`} alt=""
										/>
									</motion.div>
								</motion.div>
						}

						{/* </ OverlayTrigger> */}

					</Col>
					<Col md={3} lg={3} sm={3} xs={3}
						className={`traigo-datos  m-1 my-lg-1 ${clasePedidoCompleto}  ${!listado.motherboard.length > 0 && ocultarCategoria("ram", param)}`}
						id="ram" onClick={() => handeClick("ram")}>
						{listado.ram.length > 0 && <div className="iconContainer">
							<FaCheckCircle size={'1.5em'} color="green" />
						</div>}
						{/* <OverlayTrigger
							placement="top"
							delay={{ show: 250, hide: 400 }}
							overlay={tooltipRam} style={{zIndex:"1000000"}}> */}
						{
							paso === 'ram' ?
								<motion.div whileHover={{
									scale: 1.1,
									textShadow: "0px 0px 4px gray"
								}} className={paso === 'ram' ? 'stage' : null}
									transition={!minWidth && {
										y: transitionValues,
										width: transitionValues,
										height: transitionValues
									}}
									animate={!minWidth && {
										y: ["-0.5rem", "0.5rem"],
										width: ["5rem", "5rem", "5.4rem"],
										height: ["5rem", "5rem", "4.5rem"]
									}}>
									<motion.div>
										<img className="imgPaso d-xl-block  ramImg" src={`${imagenCategoria}ram.png`} alt=""
										/>
									</motion.div>
								</motion.div> :
								<motion.div whileHover={{
									scale: 1.1,
									textShadow: "0px 0px 4px gray"
								}} className={paso === 'ram' ? 'stage' : null}>
									<motion.div>
										<img className="imgPaso d-xl-block  ramImg" src={`${imagenCategoria}ram.png`} alt=""
										/>
									</motion.div>
								</motion.div>
						}
						{/* </ OverlayTrigger> */}
					</Col>

					<Col md={3} lg={3} sm={3} xs={3}
						className={`traigo-datos  m-1 my-lg-1 ${clasePedidoCompleto} ${!listado.ram.length > 0 && ocultarCategoria("disco", param)}`}
						id="disco" onClick={() => handeClick("disco")}>
						{listado.disco.length > 0 && <div className="iconContainer">
							<FaCheckCircle size={'1.5em'} color="green" />
						</div>}
						{/* <OverlayTrigger
							placement="top"
							delay={{ show: 250, hide: 400 }}
							overlay={tooltipDisco} style={{zIndex:"1000000"}}> */}
						{
							paso === 'disco' ?
								<motion.div whileHover={{
									scale: 1.1,
									textShadow: "0px 0px 4px gray"
								}} className={paso === 'disco' ? 'stage' : null} transition={!minWidth && {
									y: transitionValues,
									width: transitionValues,
									height: transitionValues
								}}
									animate={!minWidth && {
										y: ["-0.5rem", "0.5rem"],
										width: ["5rem", "5rem", "5.4rem"],
										height: ["5rem", "5rem", "4.5rem"]
									}}>
									<motion.div>
										<img className="imgPaso d-xl-block  discoImg" src={`${imagenCategoria}disco.png`} alt=""
										/>
									</motion.div>
								</motion.div> :
								<motion.div whileHover={{
									scale: 1.1,
									textShadow: "0px 0px 4px gray"
								}} className={paso === 'disco' ? 'stage' : null}>
									<motion.div>
										<img className="imgPaso d-xl-block  discoImg" src={`${imagenCategoria}disco.png`} alt=""
										/>
									</motion.div>
								</motion.div>
						}
						{/* </ OverlayTrigger> */}
					</Col>
					<Col md={3} lg={3} sm={3} xs={3}
						className={`traigo-datos  m-1 my-lg-1 ${clasePedidoCompleto} ${!listado.disco.length > 0 && ocultarCategoria("gabinete", param)}`}
						id="gabinete" onClick={() => handeClick("gabinete")}>
						{listado.gabinete.length > 0 && <div className="iconContainer">
							<FaCheckCircle size={'1.5em'} color="green" />
						</div>}
						{/* <OverlayTrigger
							placement="top"
							delay={{ show: 250, hide: 400 }}
							overlay={tooltipGabinete} style={{zIndex:"1000000"}}> */}
						{
							paso === 'gabinete' ?
								<motion.div whileHover={{
									scale: 1.1,
									textShadow: "0px 0px 4px gray"
								}} className={paso === 'gabinete' ? 'stage' : null} transition={!minWidth && {
									y: transitionValues,
									width: transitionValues,
									height: transitionValues
								}}
									animate={!minWidth && {
										y: ["-0.5rem", "0.5rem"],
										width: ["5rem", "5rem", "5.4rem"],
										height: ["5rem", "5rem", "4.5rem"]
									}}>
									<motion.div>
										<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block  gabineteImg" src={`${imagenCategoria}gabinete.png`} alt=""
										/>
									</motion.div>
								</motion.div> :
								<motion.div whileHover={{
									scale: 1.1,
									textShadow: "0px 0px 4px gray"
								}} className={paso === 'gabinete' ? 'stage' : null}>
									<motion.div>
										<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block  gabineteImg" src={`${imagenCategoria}gabinete.png`} alt=""
										/>
									</motion.div>
								</motion.div>
						}
						{/* </ OverlayTrigger> */}
					</Col>
					<Col md={3} lg={3} sm={3} xs={3}
						className={`traigo-datos  m-1 my-lg-1 ${clasePedidoCompleto}  ${!listado.gabinete.length > 0 && ocultarCategoria("video", param)}`}
						id="video" onClick={() => handeClick("video")}>
						{listado.video.length > 0 && <div className="iconContainer">
							<FaCheckCircle size={'1.5em'} color="green" />
						</div>}
						{/* <OverlayTrigger
							placement="top"
							delay={{ show: 250, hide: 400 }}
							overlay={tooltipVideo} style={{zIndex:"1000000"}}> */}
						{
							paso === 'video' ?
								<motion.div whileHover={{
									scale: 1.1,
									textShadow: "0px 0px 4px gray"
								}} className={paso === 'video' ? 'stage' : null} transition={!minWidth && {
									y: transitionValues,
									width: transitionValues,
									height: transitionValues
								}}
									animate={!minWidth && {
										y: ["-0.5rem", "0.5rem"],
										width: ["5rem", "5rem", "5.4rem"],
										height: ["5rem", "5rem", "4.5rem"]
									}}>
									<motion.div>
										<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block videoImg" src={`${imagenCategoria}video.png`} alt=""
										/>
									</motion.div>
								</motion.div> :
								<motion.div whileHover={{
									scale: 1.1,
									textShadow: "0px 0px 4px gray"
								}} className={paso === 'video' ? 'stage' : null}>
									<motion.div>
										<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block videoImg" src={`${imagenCategoria}video.png`} alt=""
										/>
									</motion.div>
								</motion.div>
						}
						{/* </ OverlayTrigger> */}
					</Col>
					<Col md={3} lg={3} sm={3} xs={3}
						className={`traigo-datos  m-1 my-lg-1 ${clasePedidoCompleto} ${!listado.video.length > 0 && ocultarCategoria("fuente", param)}`}
						id="fuente" onClick={() => handeClick("fuente")}>
						{listado.fuente.length > 0 && <div className="iconContainer">
							<FaCheckCircle size={'1.5em'} color="green" />
						</div>}
						{/* <OverlayTrigger
							placement="top"
							delay={{ show: 250, hide: 400 }}
							overlay={tooltipFuente} style={{zIndex:"1000000"}}> */}
						{
							paso === 'fuente' ?
								<motion.div whileHover={{
									scale: 1.1,
									textShadow: "0px 0px 4px gray"
								}} className={paso === 'fuente' ? 'stage' : null} transition={!minWidth && {
									y: transitionValues,
									width: transitionValues,
									height: transitionValues
								}}
									animate={!minWidth && {
										y: ["-0.5rem", "0.5rem"],
										width: ["5rem", "5rem", "5.4rem"],
										height: ["5rem", "5rem", "4.5rem"]
									}}>
									<motion.div>
										<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block fuenteImg" src={`${imagenCategoria}fuente.png`} alt=""
										/>
									</motion.div>
								</motion.div> :
								<motion.div whileHover={{
									scale: 1.1,
									textShadow: "0px 0px 4px gray"
								}} className={paso === 'fuente' ? 'stage' : null}>
									<motion.div>
										<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block fuenteImg" src={`${imagenCategoria}fuente.png`} alt=""
										/>
									</motion.div>
								</motion.div>
						}
						{/* </ OverlayTrigger> */}
					</Col>

					<div style={{ display: 'none' }}>
						<Col md={3} lg={3} sm={3} xs={3}
							className={`traigo-datos  m-1 my-lg-1 ${clasePedidoCompleto}  ${!listado.fuente.length > 0 && ocultarCategoria("armado", param)}`}
							id="armado" onClick={() => {
								const handleClick = handleClick("armado");
								const handleAlert = () => alertaFinalizacionCompra();
								handleClick();
								handleAlert();
							}}>
							{listado.armado.length > 0 && <div className="iconContainer">
								<FaCheckCircle size={'1.5em'} color="green" />
							</div>}

							{
								paso === 'armado' ?
									<motion.div whileHover={{
										scale: 1.1,
										textShadow: "0px 0px 4px gray"
									}} className={paso === 'armado' ? 'stage' : null} transition={!minWidth && {
										y: transitionValues,
										width: transitionValues,
										height: transitionValues
									}}
										animate={!minWidth && {
											y: ["-0.5rem", "0.5rem"],
											width: ["5rem", "5rem", "5.4rem"],
											height: ["5rem", "5rem", "4.5rem"]
										}}>
										<motion.div>
											<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block armadoImg" src={`${imagenCategoria}armado.png`} alt=""
											/>
										</motion.div>
									</motion.div> :
									<motion.div whileHover={{
										scale: 1.1,
										textShadow: "0px 0px 4px gray"
									}} className={paso === 'armado' ? 'stage' : null}>
										<motion.div>
											<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block armadoImg" src={`${imagenCategoria}armado.png`} alt=""
											/>
										</motion.div>
									</motion.div>
							}
						</Col>
					</div>

					<Col md={3} lg={3} sm={3} xs={3}
						className={`traigo-datos  m-1 my-lg-1 ${clasePedidoCompleto}  ${!listado.fuente.length > 0 && ocultarCategoria("cooler", param)}`}
						id="cooler" onClick={() => {
							const handleClick = handleClick("cooler");
							handleClick();
						}}>
						{listado.cooler.length > 0 && <div className="iconContainer">
							<FaCheckCircle size={'1.5em'} color="green" />
						</div>}
						{/* <OverlayTrigger
							placement="top"
							delay={{ show: 250, hide: 400 }}
							overlay={tooltipCooler} style={{zIndex:"1000000"}}> */}
						{
							paso === 'cooler' ? <motion.div whileHover={{
								scale: 1.1,
								textShadow: "0px 0px 4px gray"
							}} className={paso === 'cooler' ? 'stage' : null} transition={!minWidth && {
								y: transitionValues,
								width: transitionValues,
								height: transitionValues
							}}
								animate={!minWidth && {
									y: ["-0.5rem", "0.5rem"],
									width: ["5rem", "5rem", "5.4rem"],
									height: ["5rem", "5rem", "4.5rem"]
								}}>
								<motion.div>
									<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block  coolerImg" src={`${imagenCategoria}cooler.png`} alt=""
									/>
								</motion.div>
							</motion.div> :
								<motion.div whileHover={{
									scale: 1.1,
									textShadow: "0px 0px 4px gray"
								}} className={paso === 'cooler' ? 'stage' : null}>
									<motion.div>
										<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block  coolerImg" src={`${imagenCategoria}cooler.png`} alt=""
										/>
									</motion.div>
								</motion.div>
						}
						{/* </ OverlayTrigger> */}
					</Col>
					<Col md={3} lg={3} sm={3} xs={3}
						className={`traigo-datos  m-1 my-lg-1 ${clasePedidoCompleto}  ${!listado.fuente.length > 0 && ocultarCategoria("licencia", param)}`}
						id="licencia" onClick={() => handeClick("licencia")}>
						{listado.licencia.length > 0 && <div className="iconContainer">
							<FaCheckCircle size={'1.5em'} color="green" />
						</div>}
						{/* <OverlayTrigger
							placement="top"
							delay={{ show: 250, hide: 400 }}
							overlay={tooltipLicencia} style={{zIndex:"1000000"}}> */}
						{
							paso === 'licencia' ?
								<motion.div whileHover={{
									scale: 1.1,
									textShadow: "0px 0px 4px gray"
								}} className={paso === 'licencia' ? 'stage' : null} transition={!minWidth && {
									y: transitionValues,
									width: transitionValues,
									height: transitionValues
								}}
									animate={!minWidth && {
										y: ["-0.5rem", "0.5rem"],
										width: ["5rem", "5rem", "5.4rem"],
										height: ["5rem", "5rem", "4.5rem"]
									}}>
									<motion.div>
										<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block  licenciaImg" src={`${imagenCategoria}licencia.png`} alt=""
										/>
									</motion.div>
								</motion.div> :
								<motion.div whileHover={{
									scale: 1.1,
									textShadow: "0px 0px 4px gray"
								}} className={paso === 'licencia' ? 'stage' : null}>
									<motion.div>
										<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block  licenciaImg" src={`${imagenCategoria}licencia.png`} alt=""
										/>
									</motion.div>
								</motion.div>
						}
						{/* </ OverlayTrigger> */}
					</Col>
					<Col md={3} lg={3} sm={3} xs={3}
						className={`traigo-datos  m-1 my-lg-1 ${clasePedidoCompleto} ${!listado.fuente.length > 0 && ocultarCategoria("monitor", param)}`}
						id="monitor"
						onClick={() => handeClick("monitor")}>
						{listado.monitor.length > 0 && <div className="iconContainer">
							<FaCheckCircle size={'1.5em'} color="green" />
						</div>}
						{/* <OverlayTrigger
							placement="top"
							delay={{ show: 250, hide: 400 }}
							overlay={tooltipMonitor} style={{zIndex:"1000000"}}> */}
						{
							paso === 'monitor' ? <motion.div whileHover={{
								scale: 1.1,
								textShadow: "0px 0px 4px gray"
							}} className={paso === 'monitor' ? 'stage' : null} transition={!minWidth && {
								y: transitionValues,
								width: transitionValues,
								height: transitionValues
							}}
								animate={!minWidth && {
									y: ["-0.5rem", "0.5rem"],
									width: ["5rem", "5rem", "5.4rem"],
									height: ["5rem", "5rem", "4.5rem"]
								}}>
								<motion.div>
									<img whileHover={{ scale: 1.2 }} whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block  monitorImg" src={`${imagenCategoria}monitor.png`} alt=""
									/>
								</motion.div>
							</motion.div> :
								<motion.div whileHover={{
									scale: 1.1,
									textShadow: "0px 0px 4px gray"
								}} className={paso === 'monitor' ? 'stage' : null}>
									<motion.div>
										<img whileHover={{ scale: 1.2 }} whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block  monitorImg" src={`${imagenCategoria}monitor.png`} alt=""
										/>
									</motion.div>
								</motion.div>
						}
						{/* </ OverlayTrigger> */}
					</Col>
					<Col md={3} lg={3} sm={3} xs={3}
						className={`traigo-datos  m-1 my-lg-1  ${clasePedidoCompleto} ${!listado.fuente.length > 0 && ocultarCategoria("teclado", param)}`}
						id="teclado" onClick={() => handeClick("teclado")}>
						{listado.teclado.length > 0 && <div className="iconContainer">
							<FaCheckCircle size={'1.5em'} color="green" />
						</div>}
						{/* <OverlayTrigger
							placement="top"
							delay={{ show: 250, hide: 400 }}
							overlay={tooltipTeclado} style={{zIndex:"1000000"}}> */}
						{
							paso === 'teclado' ?
								<motion.div whileHover={{
									scale: 1.1,
									textShadow: "0px 0px 4px gray"
								}} className={paso === 'teclado' ? 'stage' : null} transition={!minWidth && {
									y: transitionValues,
									width: transitionValues,
									height: transitionValues
								}}
									animate={!minWidth && {
										y: ["-0.5rem", "0.5rem"],
										width: ["5rem", "5rem", "5.4rem"],
										height: ["5rem", "5rem", "4.5rem"]
									}}>
									<motion.div>
										<img whileHover={{ scale: 1.2 }} whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block  tecladoImg" src={`${imagenCategoria}teclado.png`} alt=""
										/>
									</motion.div>
								</motion.div> :
								<motion.div whileHover={{
									scale: 1.1,
									textShadow: "0px 0px 4px gray"
								}} className={paso === 'teclado' ? 'stage' : null}>
									<motion.div>
										<img whileHover={{ scale: 1.2 }} whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block  tecladoImg" src={`${imagenCategoria}teclado.png`} alt=""
										/>
									</motion.div>
								</motion.div>
						}
						{/* </ OverlayTrigger> */}
					</Col>
					<Col md={3} lg={3} sm={3} xs={3}
						className={`traigo-datos  m-1 my-lg-1  ${clasePedidoCompleto} ${!listado.fuente.length > 0 && ocultarCategoria("mouse", param)}`}
						id="mouse" onClick={() => handeClick("mouse")}>
						{listado.mouse.length > 0 && <div className="iconContainer">
							<FaCheckCircle size={'1.5em'} color="green" />
						</div>}
						{/* <OverlayTrigger
							placement="top"
							delay={{ show: 250, hide: 400 }}
							overlay={tooltipMouse} style={{zIndex:"1000000"}}> */}
						{
							paso === 'mouse' ?
								<motion.div whileHover={{
									scale: 1.1,
									textShadow: "0px 0px 4px gray"
								}} className={paso === 'mouse' ? 'stage' : null} transition={!minWidth && {
									y: transitionValues,
									width: transitionValues,
									height: transitionValues
								}}
									animate={!minWidth && {
										y: ["-0.5rem", "0.5rem"],
										width: ["5rem", "5rem", "5.4rem"],
										height: ["5rem", "5rem", "4.5rem"]
									}} >
									<motion.div>
										<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block  mouseImg" src={`${imagenCategoria}mouses.png`} alt=""
										/>
									</motion.div>
								</motion.div> :
								<motion.div whileHover={{
									scale: 1.1,
									textShadow: "0px 0px 4px gray"
								}} className={paso === 'mouse' ? 'stage' : null}>
									<motion.div>
										<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block  mouseImg" src={`${imagenCategoria}mouses.png`} alt=""
										/>
									</motion.div>
								</motion.div>
						}
						{/* </ OverlayTrigger> */}
					</Col>
					<Col md={3} lg={3} sm={3} xs={3}
						className={`traigo-datos  m-1 my-lg-1  ${clasePedidoCompleto}  ${!listado.fuente.length > 0 && ocultarCategoria("auricular", param)}`}
						id="auricular" onClick={() => handeClick("auricular")}>
						{listado.auricular.length > 0 && <div className="iconContainer">
							<FaCheckCircle size={'1.5em'} color="green" />
						</div>}
						{/* <OverlayTrigger
							placement="top"
							delay={{ show: 250, hide: 400 }}
							overlay={tooltipAuricular} style={{zIndex:"1000000"}}> */}
						{
							paso === 'auricular' ?
								<motion.div whileHover={{
									scale: 1.1,
									textShadow: "0px 0px 4px gray"
								}} className={paso === 'auricular' ? 'stage' : null} transition={!minWidth && {
									y: transitionValues,
									width: transitionValues,
									height: transitionValues
								}}
									animate={!minWidth && {
										y: ["-0.5rem", "0.5rem"],
										width: ["5rem", "5rem", "5.4rem"],
										height: ["5rem", "5rem", "4.5rem"]
									}}>
									<motion.div>
										<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block auricularImg" src={`${imagenCategoria}auricular.png`} alt=""
										/>
									</motion.div>
								</motion.div> : <motion.div whileHover={{
									scale: 1.1,
									textShadow: "0px 0px 4px gray"
								}} className={paso === 'auricular' ? 'stage' : null}>
									<motion.div>
										<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block auricularImg" src={`${imagenCategoria}auricular.png`} alt=""
										/>
									</motion.div>
								</motion.div>
						}
						{/* </ OverlayTrigger> */}
					</Col>
					<Col md={3} lg={3} sm={3} xs={3}
						className={`traigo-datos  m-1 my-lg-1 ${clasePedidoCompleto}  ${!listado.fuente.length > 0 && ocultarCategoria("silla", param)}`}
						id="silla" onClick={() => handeClick("silla")}>
						{listado.silla.length > 0 && <div className="iconContainer">
							<FaCheckCircle size={'1.5em'} color="green" />
						</div>}
						{/* <OverlayTrigger
							placement="top"
							delay={{ show: 250, hide: 400 }}
							overlay={tooltipSilla} style={{zIndex:"1000000"}}> */}
						{
							paso === 'silla' ?
								<motion.div whileHover={{
									scale: 1.1,
									textShadow: "0px 0px 4px gray"
								}} className={paso === 'silla' ? 'stage' : null} transition={!minWidth && {
									y: transitionValues,
									width: transitionValues,
									height: transitionValues
								}}
									animate={!minWidth && {
										y: ["-0.5rem", "0.5rem"],
										width: ["5rem", "5rem", "5.4rem"],
										height: ["5rem", "5rem", "4.5rem"]
									}} >
									<motion.div>
										<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block sillaImg" src={`${imagenCategoria}silla.png`} alt=""
										/>
									</motion.div>
								</motion.div> :
								<motion.div whileHover={{
									scale: 1.1,
									textShadow: "0px 0px 4px gray"
								}} className={paso === 'silla' ? 'stage' : null}>
									<motion.div>
										<img whileTap={{ scale: 1.7 }} className="imgPaso d-xl-block sillaImg" src={`${imagenCategoria}silla.png`} alt=""
										/>
									</motion.div>
								</motion.div>
						}
						{/* </ OverlayTrigger> */}
					</Col>
				</motion.div>


			</motion.div>

		</motion.div>
	)
} 
